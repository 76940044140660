import React, { Component } from 'react'
import { Link } from 'gatsby'
import styles from './styles.module.scss'

export interface HeaderLinkProps {
  href: string
  children: any
  className?: string
}

export class HeaderLink extends Component<HeaderLinkProps> {
  render() {
    const { href, children, className } = this.props
    return (
      <div className={[styles.link_wrapper, className].join(' ')}>
        <Link
          to={href}
          getProps={({ isPartiallyCurrent }) => {
            return isPartiallyCurrent ? { className: styles.link_selected } : null
          }}
        >
          {children}
        </Link>
      </div>
    )
  }
}
