import React, { Component } from 'react'
import { Container, Col, Row, Img } from '~components'
import { ILetterSection } from '~models'
import styles from './styles.module.scss'
import { Icons } from '~libs/assets'
import { nbsp } from '~libs/constants'
import { tx } from '~libs/i18n'

export interface LetterSectionProps {
  section: ILetterSection
}

export interface LetterSectionState {
  open: boolean
}

export class LetterSection extends Component<LetterSectionProps, LetterSectionState> {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    const {
      title,
      text,
      text_extended,
      job_title,
      name,
      background_color,
      signature,
    } = this.props.section
    const { open } = this.state

    return (
      <section className={styles.section} style={{ backgroundColor: background_color }}>
        <Container main className={styles.container}>
          <Row>
            <Col md={11} lg={10}>
              <h2 className={styles.title}>{title}</h2>
            </Col>
          </Row>
          <Row>
            <Col md={7} lg={6} xl={5}>
              <p className={'body_regular'}>
                {text}
                <br />
              </p>
              <p
                className={['body_regular', open ? '' : 'd-none d-md-inline-block'].join(
                  ' ',
                )}
              >
                {text_extended}
                <br />
                <br />
              </p>
              <p
                className={[styles.click, 'd-inline-block d-md-none'].join(' ')}
                onClick={() => {
                  this.setState({ open: !open })
                }}
              >
                {open
                  ? `${tx('landing_page.letter.button_show_less')}${nbsp}${nbsp}`
                  : `${tx('landing_page.letter.button_show_more')}${nbsp}${nbsp}`}

                <img
                  src={Icons.arrow_down}
                  className={[styles.arrow_down, open ? styles.upsideDown : ''].join(' ')}
                  onClick={() => {
                    this.setState({ open: !open })
                  }}
                />
                <br />
              </p>
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={styles.signature}>{signature}</p>
              <p className={['body_medium', styles.signatureText].join(' ')}>
                {job_title} <br />
                {name}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}
