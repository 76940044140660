import React, { Component, InputHTMLAttributes, RefObject, ChangeEvent } from 'react'
import { Icons } from '~libs/assets'
import { FormatterProps } from '~libs/formatters'
import styles from './styles.module.scss'

export interface ISelectValue {
  name: string
  value: any
  key?: string
}

type InputTypes = HTMLInputElement | HTMLSelectElement

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  key?: string
  maxw?: string
  minw?: string
  widthMatchContent?: boolean
  type?: 'text' | 'number' | 'boolean' | 'select' | 'tel' | 'email' | 'password'
  label?: string
  label_secondary?: string
  value?: any
  focus?: boolean
  blur?: boolean
  error?: string
  selectableValues?: ISelectValue[] // In case of type=select
  onChange?: (string) => void
  onEnter?: (InputTypes?) => void
  onFocus?: () => void
  formatter?: FormatterProps
}

export class Input extends Component<InputProps> {
  inputRef: RefObject<HTMLInputElement>
  selectRef: RefObject<HTMLSelectElement>

  selectionStart?: number
  selectionEnd?: number

  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.selectRef = React.createRef()
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate() {
    this.init()
  }

  init = () => {
    const { blur, focus } = this.props
    let current = this.getCurrentInput()

    if (current && (blur || focus)) {
      setTimeout(() => {
        blur && current && current.blur()
        focus && current && current.focus()
      }, 10)
    }

    if (current && !isNaN(this.selectionStart) && !isNaN(this.selectionEnd)) {
      this.preserveSelection(current as HTMLInputElement)
    }
  }

  preserveSelection = (input: HTMLInputElement) => {
    const type = this.props.type || 'text'
    if (type === 'text') {
      input.setSelectionRange(this.selectionStart, this.selectionEnd)
    }
    this.selectionStart = null
    this.selectionEnd = null
  }

  onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { onChange } = this.props

    if (onChange) {
      let value = e.target.value
      value = this.format(value)
      onChange(value)
    }
  }

  onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props

    const value = this.format(e.target.value)
    const diff = value.length - e.target.value.length

    this.selectionStart = e.target.selectionStart + diff
    this.selectionEnd = e.target.selectionEnd + diff

    onChange && onChange(value)
  }

  onKeyPress = (e: React.KeyboardEvent) => {
    const { onEnter } = this.props
    const keycode = e.keyCode ? e.keyCode : e.which
    switch (keycode) {
      case 13: // Enter
        onEnter && onEnter(this.getCurrentInput())
        break
    }
  }

  format = (value: string) => {
    const { type, formatter } = this.props
    if (type === 'number' && !isNaN(parseInt(value, 10))) {
      const min = this.props.min as number
      const max = this.props.max as number

      const v = parseInt(value, 10)
      if (!isNaN(min) && v < min) {
        value = min.toString()
      } else if (!isNaN(max) && v > max) {
        value = max.toString()
      }
    }
    if (formatter) {
      return formatter(value) as string
    }
    return value
  }

  getCurrentInput = () => {
    if (this.inputRef.current) {
      return this.inputRef.current
    } else if (this.selectRef.current) {
      return this.selectRef.current
    }
  }

  focus = () => {
    const current = this.getCurrentInput()
    current && current.focus()
  }

  blur = () => {
    const current = this.getCurrentInput()
    current && current.blur()
  }

  renderInput = () => {
    const {
      value,
      maxw,
      minw,
      widthMatchContent,
      type,
      placeholder,
      label,
      focus,
      onChange,
      onEnter,
      formatter,
      error,
      blur,
      className,
      style,
      ...rest
    } = this.props
    const classes = [styles.input, 'input']

    if (className) {
      classes.push(className)
    }

    const mStyle: any = {}
    if (maxw) {
      mStyle.maxWidth = maxw
    }

    if (widthMatchContent) {
      const v = value || ''
      const p = placeholder || ''
      let text = v.length > p.length ? v : p
      const spaceMatches = text.match(/\s+/g)
      const spaceCount = spaceMatches ? spaceMatches.length : 0
      let width = text.length
      width = Math.max(width, 3)
      mStyle.width = `calc(${width - spaceCount}ch + ${spaceCount * 0.25}em)`
    }

    return (
      <input
        ref={this.inputRef}
        value={value}
        type={type || 'text'}
        placeholder={placeholder}
        onChange={this.onInputChange}
        onKeyPress={this.onKeyPress}
        className={classes.join(' ')}
        style={{ ...mStyle, ...(style || {}) }}
        {...rest}
      />
    )
  }

  renderBoolean = () => {
    const { value, className } = this.props

    const classes = [styles.boolean_wrapper, 'input']

    if (className) {
      classes.push(className)
    }

    return (
      <div className={classes.join(' ')}>
        <label>
          <input
            type="radio"
            value={'Ja'}
            checked={value === 'Ja'}
            onChange={this.onChange}
          />
          <span>
            <span className={styles.mark} />
            Ja
          </span>
        </label>
        <label>
          <input
            type="radio"
            value={'Nej'}
            checked={value === 'Nej'}
            onChange={this.onChange}
          />
          <span>
            <span className={styles.mark} />
            Nej
          </span>
        </label>
      </div>
    )
  }

  renderSelect = () => {
    const {
      value,
      selectableValues,
      onFocus,
      maxw,
      widthMatchContent,
      className,
      style,
    } = this.props

    const selectClasses = [styles.select]
    const selectSpanClasses = [styles.select, styles.select_width_expander]
    const wrapperClasses = [styles.select_wrapper]
    if (className) {
      selectSpanClasses.push(className)
      wrapperClasses.push(className)
    }

    let selectedOption: ISelectValue
    const options = selectableValues.map(v => {
      if (v.value === value) {
        selectedOption = v
      }
      return (
        <option key={v.key || v.name} value={v.value}>
          {v.name}
        </option>
      )
    })

    const mStyle: any = {}
    if (maxw) {
      mStyle.maxWidth = maxw
    }

    if (widthMatchContent) {
      selectClasses.push(styles.width_match_content)
      wrapperClasses.push(styles.noBottomBorder)
    }

    return (
      <div className={wrapperClasses.join(' ')}>
        <select
          className={selectClasses.join(' ')}
          value={value}
          ref={this.selectRef}
          onChange={this.onChange}
          onFocus={e => {
            //e.target.size = options.length
            onFocus && onFocus()
          }}
          onBlur={e => {
            //e.target.size = 0
          }}
          onKeyPress={this.onKeyPress}
          style={{
            ...mStyle,
            ...(style || {}),
          }}
        >
          <option value="" disabled>
            Välj
          </option>
          {options}
        </select>
        {widthMatchContent && (
          <span className={selectSpanClasses.join(' ')}>
            {selectedOption ? selectedOption.name : 'Välj'}
          </span>
        )}
        <img src={Icons.arrow_down} className={styles.arrow_down} />
      </div>
    )
  }

  render() {
    const { label, error, type } = this.props

    let input
    switch (type) {
      case 'select':
        input = this.renderSelect()
        break
      case 'boolean':
        input = this.renderBoolean()
        break
      default:
        input = this.renderInput()
    }

    return (
      <>
        {!!label && <label className={`input_label ${styles.label}`}>{label}</label>}
        {input}
        {!!error && (
          <p className={['error', 'input_error', styles.error].join(' ')}>
            <span>{error}</span>
            <img src={Icons.error} />
          </p>
        )}
      </>
    )
  }
}
