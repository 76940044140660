export const disableScroll = () => {
  const currentScrollTop = document.body.scrollTop
  document.body.classList.add('overflow-hidden')
  document.documentElement.classList.add('overflow-hidden')
  setTimeout(() => {
    document.body.classList.add('h-100')
    document.documentElement.classList.add('h-100')
  }, 400)

  return currentScrollTop
}

export const enableScroll = (scrollTop = 0) => {
  document.body.classList.remove('overflow-hidden')
  document.documentElement.classList.remove('overflow-hidden')
  document.body.classList.remove('h-100')
  document.documentElement.classList.remove('h-100')
  document.body.scrollTop = scrollTop
}
