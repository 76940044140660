import React, { Component } from 'react'
import styles from './styles.module.scss'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  color: 'yellow' | 'white' | 'green'
}

export class Card extends Component<CardProps> {
  render() {
    const { color, className, ...rest } = this.props
    const classes = ['card', styles.card, styles[color]]
    if (className) {
      classes.push(className)
    }

    return <div className={classes.join(' ')} {...rest} />
  }
}
