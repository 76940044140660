import React, { Component } from 'react'
import { ISections } from '~models'
import {
  HeadSection,
  MortgageCalculatorSection,
  ThreeTextsSection,
  ThreeIconTextsSection,
  PictureButtonSection,
  FloatLeftRightSection,
  LetterSection,
  TestimonialSection,
} from '~sections'

export interface SectionsProps {
  sections?: ISections
}

export class Sections extends Component<SectionsProps> {
  render() {
    const { sections } = this.props
    const renderedSections = (sections || []).map((section, i) => {
      switch (section.__typename) {
        case 'WordPressAcf_header':
          return <HeadSection key={section.id} section={section} />
        case 'WordPressAcf_mortgage_calculator':
          return <MortgageCalculatorSection key={section.id} section={section} />
        case 'WordPressAcf_three_texts':
          return <ThreeTextsSection key={section.id} section={section} />
        case 'WordPressAcf_three_icon_texts':
          return <ThreeIconTextsSection key={section.id} section={section} />
        case 'WordPressAcf_picture_button':
          return <PictureButtonSection key={section.id} section={section} />
        case 'WordPressAcf_float_left_right':
          return <FloatLeftRightSection key={section.id} section={section} />
        case 'WordPressAcf_letter':
          return <LetterSection key={section.id} section={section} />
        case 'WordPressAcf_testimonial':
          return <TestimonialSection key={section.id} section={section} />
      }
      return <div key={i.toString()} />
    })

    return renderedSections
  }
}
