import React, { Component } from 'react'
import { Link } from 'gatsby'

export interface AProps extends React.HTMLAttributes<HTMLAnchorElement> {
  href?: string
}

export class A extends Component<AProps> {
  render() {
    const { href, ...rest } = this.props
    return (
      <Link to={href}>
        <a {...rest} />
      </Link>
    )
  }
}
