import React, { Component } from 'react'
import { Container, Col, Row } from '~components'
import { IFooterLink } from '~models'
import { Icons } from '~libs/assets'
import { tx } from '~libs/i18n'
import { FooterLink } from './FooterLink'
import styles from './styles.module.scss'

export interface FooterProps {
  options?: {
    facebook: string
    twitter: string
    instagram: string
  }
  links?: IFooterLink[]
  brief?: boolean
}

export class Footer extends Component<FooterProps> {
  render() {
    const { brief, options, links } = this.props

    if (brief) {
      return (
        <footer className={styles.footer_brief}>
          <Container className={styles.container_brief}>
            <span className={`legal`}>{tx('footer.legal')}</span>
            <img src={Icons.bank_id} className={styles.bank_id} />
          </Container>
        </footer>
      )
    }

    return (
      <footer className={styles.footer}>
        <Container main>
          <Row>
            {links.map(e => {
              return (
                <Col md={3} sm={4} xs={6} key={e.heading}>
                  <p className={['body-regular', styles.heading].join(' ')}>
                    {e.heading}
                  </p>
                  {e.links.map(link => {
                    return (
                      <FooterLink
                        key={link.title}
                        href={`/${link.link.post_name}`}
                        className={`body-small`}
                      >
                        {link.title}
                      </FooterLink>
                    )
                  })}
                </Col>
              )
            })}
            <Col
              md={6}
              sm={4}
              xs={6}
              className={
                'd-flex flex-row justify-content-sm-start justify-content-sm-end align-items-sm-start'
              }
            >
              {options.twitter && (
                <a href={options.twitter}>
                  <img src={Icons.twitter} className={styles.twitter} />
                </a>
              )}
              {options.facebook && (
                <a href={options.facebook}>
                  <img src={Icons.facebook} className={styles.facebook} />
                </a>
              )}
              {options.instagram && (
                <a href={options.instagram}>
                  <img src={Icons.instagram} className={styles.instagram} />
                </a>
              )}
            </Col>
            <Col
              xl={7}
              lg={7}
              md={6}
              sm={2}
              xs={6}
              className={
                'd-flex flex-row justify-content-end align-items-end order-xs-1 order-sm-2'
              }
            >
              <img src={Icons.bank_id} className={styles.bank_id} />
            </Col>
            <Col xl={5} lg={5} md={6} sm={10} xs={12} className="order-xs-2 order-sm-1">
              <p className={`legal ${styles.legal}`}>{tx('footer.legal')}</p>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}
