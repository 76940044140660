import React, { Component } from 'react'
import { IImage } from '~models'

export interface ImgProps extends React.HTMLAttributes<HTMLDivElement> {
  image: IImage
  size?: 'small' | 'medium' | 'large'
}

export class Img extends Component<ImgProps> {
  render() {
    const { image, style, className, size, ...rest } = this.props
    const classes = []

    if (className) {
      classes.push(className)
    }

    const imageUrl =
      image && image.localFile
        ? image.localFile.childImageSharp[size || 'large'].src
        : null

    return <img {...rest} className={classes.join(' ')} style={style} src={imageUrl} />
  }
}
