import React, { Component, CSSProperties } from 'react'
import styles from './styles.module.scss'

export interface DivProps extends React.HTMLAttributes<HTMLDivElement> {
  flex?: number
  square?: boolean
  background?: string
}

export class Div extends Component<DivProps> {
  render() {
    const { square, flex, background, style, className, ...rest } = this.props

    const mstyle: CSSProperties = {
      whiteSpace: 'pre-wrap',
      ...(style || {}),
    }

    const classes = []
    if (square) {
      classes.push(styles.square)
    }

    if (!!background) {
      classes.push(styles.background_image)
      mstyle.backgroundImage = `url(${background})`
    }

    if (typeof flex !== 'undefined') {
      mstyle.flex = flex
    }

    if (className) {
      classes.push(className)
    }

    return <div {...rest} className={classes.join(' ')} style={mstyle} />
  }
}
