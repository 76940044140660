import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationUIStore, ApplicationQueueStore } from 'stores'
import { Container, Row, Col, Div, Responsive } from '~components'
import { IHeaderLink, IActionButton } from '~models'
import { screenWidthLg } from '~libs/constants'
import { HeaderLink } from './HeaderLink'
import styles from './styles.module.scss'
import { Button } from '../shared/components'

interface HeaderLinksProps extends React.HTMLAttributes<HTMLDivElement> {
  applicationUIStore?: ApplicationUIStore
  applicationQueueStore?: ApplicationQueueStore
  menuOpen: boolean
  closeMenu: any
  links?: IHeaderLink[]
  buttons?: IActionButton[]
}

interface HeaderLinksState extends React.HTMLAttributes<HTMLDivElement> {
  isWindowSmall: boolean
}

@inject('applicationUIStore', 'applicationQueueStore')
@observer
export class HeaderLinks extends Component<HeaderLinksProps, HeaderLinksState> {
  constructor(props) {
    super(props)

    this.state = {
      isWindowSmall: false,
    }

    this.windowWidthChanged = this.windowWidthChanged.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowWidthChanged)
    this.windowWidthChanged()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.windowWidthChanged)
  }

  windowWidthChanged() {
    const width = window.innerWidth
    const { isWindowSmall } = this.state
    if (
      (isWindowSmall && width >= screenWidthLg) ||
      (!isWindowSmall && width < screenWidthLg)
    ) {
      if (width >= screenWidthLg) {
        this.props.closeMenu()
      }
      this.setState({ isWindowSmall: !isWindowSmall })
    }
  }

  renderDrawer = () => {
    const { menuOpen, closeMenu, links } = this.props
    return (
      <Div className={'d-lg-none'}>
        <Div
          onClick={closeMenu}
          className={[styles.greyCover, menuOpen ? '' : styles.notVisible].join(' ')}
        />
        <Container
          fluid
          className={[styles.openMenu, menuOpen ? '' : styles.notVisible].join(' ')}
        >
          <Row className={styles.openMenuRow} />
          {/* Rendering the links */}
          {links.map(e => {
            return (
              <Row key={e.title} className={styles.openMenuRow}>
                <Col className={'d-flex align-items-center justify-content-start'}>
                  <HeaderLink href={`/${e.link.post_name}`}>{e.title}</HeaderLink>
                </Col>
              </Row>
            )
          })}
          <Row className={styles.openMenuButtonContainer}>
            <Col>{this.renderActionButtons(true)}</Col>
          </Row>
        </Container>
      </Div>
    )
  }

  renderActionButtons = (forDrawer: boolean) => {
    const { applicationUIStore, applicationQueueStore, buttons } = this.props
    if (!buttons) {
      return null
    }
    return buttons.map((button, index) => {
      const classes: string[] = []
      if (index % 2 == 0) {
        if (forDrawer) {
          classes.push(styles.openMenuButtonWhite)
        } else {
          classes.push(styles.outlined)
        }
      } else {
        if (forDrawer) {
          classes.push(styles.openMenuButtonBlue)
        } else {
          classes.push(styles.filled)
        }
      }

      let href = null
      let onClick = null

      switch (button.type) {
        case 'link_to_page':
          href = `/${button.page_link.post_name}`
          break
        case 'my_pages':
          href = '/privat'
          break
        case 'open_application':
          onClick = applicationUIStore.open
          break
        case 'open_application_queue':
          onClick = applicationQueueStore.open
          break
        case 'open_application_invited':
          onClick = applicationQueueStore.openInvited
          break
      }

      if (onClick !== null) {
        classes.push(button.type)
        if (forDrawer) {
          classes.push('drawer')
        }
        return (
          <Button key={`action_${index}`} className={classes.join(' ')} onClick={onClick}>
            <Responsive>
              {button.button_title_xs}
              {button.button_title}
            </Responsive>
          </Button>
        )
      }

      if (href !== null) {
        const wrapperClasses: string[] = [button.type]
        if (forDrawer) {
          wrapperClasses.push(styles.drawer_link)
          wrapperClasses.push('drawer')
        }
        return (
          <HeaderLink
            key={`action_${index}`}
            href={href}
            className={wrapperClasses.join(' ')}
          >
            <Button className={classes.join(' ')}>
              <Responsive>
                {button.button_title_xs}
                {button.button_title}
              </Responsive>
            </Button>
          </HeaderLink>
        )
      }
      return null
    })
  }

  render() {
    const { links } = this.props
    return (
      <Div className={styles.links_wrapper}>
        {/* Rendering the links */}
        {links.map(e => {
          return (
            <HeaderLink
              key={e.title}
              href={`/${e.link.post_name}`}
              className={'d-none d-lg-inline-block'}
            >
              {e.title}
            </HeaderLink>
          )
        })}

        {this.renderActionButtons(false)}
        {this.renderDrawer()}
      </Div>
    )
  }
}
