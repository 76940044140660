import React, { Component } from 'react'
import { ImageButton, Button } from '~components'
import { Container, Row, Div } from '~components'
import { Icons } from '~libs/assets'
import { IHeaderLink, IActionButton } from '~models'
import { HeaderLinks } from './HeaderLinks'
import { HeaderLogo } from './HeaderLogo'
import Hamburger from './Hamburger'
import styles from './styles.module.scss'

interface HeaderProps {
  className?: string
  modal?: boolean
  logout?: boolean
  onClose?: () => void
  links?: IHeaderLink[]
  buttons?: IActionButton[]
}

interface HeaderState {
  menuOpen: boolean
}

export class Header extends Component<HeaderProps, HeaderState> {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }

  closeButton = () => {
    const { logout, onClose } = this.props

    if (logout) {
      return (
        <Button onClick={onClose} styleKey="logout">
          Logga ut
        </Button>
      )
    }
    return <ImageButton onClick={onClose} src={Icons.close} />
  }

  render() {
    const { className, modal, links, buttons } = this.props
    const { menuOpen } = this.state
    const classes = [styles.header]
    if (className) {
      classes.push(className)
    }
    return (
      <header className={classes.join(' ')}>
        <Container fluid>
          <Row className="d-flex align-items-center justify-content-between">
            <Div className={styles.left}>
              {!modal && (
                <Hamburger
                  menuOpen={menuOpen}
                  onClick={() => this.setState({ menuOpen: !menuOpen })}
                  className={[
                    'd-inline-block d-lg-none',
                    styles.removeMarginLeft,
                    styles.hamburger,
                  ].join(' ')}
                />
              )}
              <HeaderLogo />
            </Div>
            <Div className={styles.right}>
              {!modal && (
                <HeaderLinks
                  links={links}
                  buttons={buttons}
                  menuOpen={menuOpen}
                  closeMenu={() => this.setState({ menuOpen: false })}
                />
              )}
              {!!modal && this.closeButton()}
            </Div>
          </Row>
        </Container>
      </header>
    )
  }
}
