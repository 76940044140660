import { withPrefix } from 'gatsby'

export const Icons: { [key: string]: string } = {
  error: withPrefix('/icons/error.svg'),
  close: withPrefix('/icons/close.svg'),
  arrow_down: withPrefix('/icons/arrow_down.svg'),
  arrow_right: withPrefix('/icons/arrow_right.svg'),
  twitter: withPrefix('/icons/twitter.svg'),
  facebook: withPrefix('/icons/facebook.svg'),
  instagram: withPrefix('/icons/instagram.svg'),
  bank_id: withPrefix('/icons/bank_id.png'),
  check_mark: withPrefix('/icons/check_mark.svg'),
  check_circle: withPrefix('/icons/check_circle.svg'),
  check_circle_green: withPrefix('/icons/check_circle_green.svg'),
  lock: withPrefix('/icons/lock.svg'),
  video_library: withPrefix('/icons/video_library.svg'),
  hemma_logo: withPrefix('/icons/hemma.svg'),
  plus: withPrefix('/icons/plus.svg'),
}
