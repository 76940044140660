import React, { Component } from 'react'

import { Container, Col, Row, ActionButton, ImgBundle, Div } from '~components'
import { IPictureButtonSection } from '~models'
import styles from './styles.module.scss'

export interface PictureButtonSectionProps {
  section: IPictureButtonSection
}

export class PictureButtonSection extends Component<PictureButtonSectionProps> {
  render() {
    const { section } = this.props
    const { images, title, action_button, background_color } = section
    return (
      <section
        className={styles.section}
        style={background_color ? { backgroundColor: background_color } : null}
      >
        <ImgBundle images={images} className={styles.backgroundImage} />
        <Div className={styles.absolute}>
          <Container centered main>
            <Row className={'w-100'}>
              <Col lg={2} md={1} sm={0} />
              <Col lg={8} md={10}>
                <h2 className={styles.title}>{title}</h2>
              </Col>
            </Row>

            <ActionButton
              rounded
              className={styles.buttonLarge}
              actionButton={action_button}
            />
          </Container>
        </Div>
      </section>
    )
  }
}
