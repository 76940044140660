import { action, observable, toJS } from 'mobx'
import {
  applicant,
  has_coapplicant,
  has_coapplicant_number,
  collateral,
  debt,
} from './models'
import { IInputState, IInputItem, ITrigger } from './props'
import { path, enableScroll, disableScroll } from '~libs/utils'
import { RequiredValidator } from '~libs/validators'
import { parseToConfig } from './parser'
import { keyInfoMapApplicant, keyInfoMapCoapplicant, IViewStoreInfo } from './keyInfoMap'

export type typeApplicants = 'applicant' | 'coapplicant'

const inputState: IInputState = {
  debt,
  applicant,
}

export class ApplicationUIStore {
  @observable visible: boolean = false
  @observable screenIndex: number = 0
  @observable inputStepIndex: number = 0
  @observable inputStateMap: {
    applicant: IInputState
    coapplicant: IInputState
  }

  @observable applicantKeyInfoMap = {
    applicant: keyInfoMapApplicant,
    coapplicant: keyInfoMapCoapplicant,
  }

  bodyScrollTop = 0

  constructor() {
    this.reset()
    this.visible && this.open()
  }

  @action reset = async () => {
    this.inputStateMap = {
      applicant: {
        ...inputState,
        collateral,
        has_coapplicant,
        has_coapplicant_number,
      },
      coapplicant: inputState,
    }
    this.inputStepIndex = -1
  }

  getKeyInfo: (string, typeApplicants) => IViewStoreInfo = (
    key: string,
    type: typeApplicants = 'applicant',
  ) => this.applicantKeyInfoMap[type][key]
  /**
   * Will run triggers and update the item with the provided value
   *
   * @param  {IInputItem} item Item to check for possible triggers and update its value
   * @param  {string} value The value to update to
   * @param  {IInputState} siblings? The parent-map of item
   */
  @action set = (
    item: IInputItem,
    value: string,
    type: typeApplicants,
    siblings?: IInputState,
  ) => {
    this.checkTrigger(item, value, type, siblings)
    item.value = value
  }

  /**
   * Will run all triggers for the selected item
   *
   * @param  {IInputItem} item Item to check for possible triggers and update its value
   * @param  {any} value Value that the item will be updated with
   * @param  {IInputState} siblings? The parent-map of item. If siblings is not defined, item.storepath will be used to resolve the parent (storepath may be undefined for item.isArray = true)
   */
  checkTrigger = (
    item: IInputItem,
    value: any,
    type: typeApplicants,
    siblings?: IInputState,
  ) => {
    if (!item.triggers || item.value === value) {
      return
    }

    if (!siblings) {
      const parentPath = [...item.storepath]
      parentPath.pop()
      siblings = this.get(parentPath, type) as IInputState
    }

    item.triggers.forEach(trigger => {
      switch (trigger.type) {
        case 'copyValue': {
          this.runTriggerCopyValue(value, trigger, siblings)
          break
        }
        default: {
          // Check if we triggered this option
          const didTrigger = Array.isArray(trigger.value.slice())
            ? (trigger.value as string[]).find(v => v === value)
            : trigger.value === value

          if (trigger.active && !didTrigger) {
            this.runTrigger(trigger, siblings, false)
          } else if (!trigger.active && didTrigger) {
            this.runTrigger(trigger, siblings, true)
          }
        }
      }
    })
  }
  /**
   * Run the trigger on any possible sibling
   *
   * @param  {ITrigger} trigger Trigger to execute
   * @param  {IInputState} siblings Items that the trigger may affect
   * @param  {boolean} activate Whether to activate or deactivate the trigger
   */
  @action runTrigger = (trigger: ITrigger, siblings: IInputState, activate: boolean) => {
    trigger.keys.forEach(key => {
      const inputItem: IInputItem = siblings[key]
      if (inputItem) {
        inputItem.ishidden = !activate
      }
    })

    trigger.active = activate
  }

  /**
   * Set the value to all items that the trigger affects
   *
   * @param  {any} value The value to set to items
   * @param  {ITrigger} trigger Trigger to execute
   * @param  {IInputState} siblings Items that the trigger may affect
   */
  @action runTriggerCopyValue = (
    value: any,
    trigger: ITrigger,
    siblings: IInputState,
  ) => {
    trigger.keys.forEach(key => {
      const inputItem: IInputItem = siblings[key]
      inputItem.value = value
    })
  }

  has_key = (key: string | string[], type: typeApplicants) => {
    const keyPath = Array.isArray(key) ? key : key.split('.')
    const input = path(keyPath, this.inputStateMap[type]) as IInputItem
    return !!input
  }

  get = (key: string | string[], type: typeApplicants) => {
    const keyPath = Array.isArray(key) ? key : key.split('.')
    const input = path(keyPath, this.inputStateMap[type]) as IInputItem
    if (!input) {
      throw Error(`Invalid keypath: '${keyPath}', type: '${type}'`)
    }
    if (!input.storepath) {
      input.storepath = keyPath
    }
    return input
  }

  hasCoapplicant = () => {
    return this.inputStateMap.applicant.has_coapplicant_number.value > 1
  }

  @action close = () => {
    this.visible = false

    enableScroll(this.bodyScrollTop)
    setTimeout(() => {
      this.screenIndex = 0
    }, 400)
  }

  @action open = () => {
    this.visible = true
    this.bodyScrollTop = disableScroll()
  }

  @action goToScreen = index => {
    this.screenIndex = Math.max(0, index)
  }

  @action previousScreen = () => {
    this.screenIndex--
  }

  @action nextScreen = () => {
    this.screenIndex++
  }

  @action setInputStepIndex = index => {
    this.inputStepIndex = index
  }

  @action incrementInputStepIndex = () => {
    this.inputStepIndex++
  }

  @action decrementInputStepIndex = () => {
    this.inputStepIndex--
  }

  @action validate = (inputItem: IInputItem, setError: boolean) => {
    if (inputItem.isArray) {
      let valid = true
      if (!inputItem.value.length) {
        valid = false
      } else {
        inputItem.value.forEach(innerItems => {
          Object.keys(innerItems).forEach(key => {
            if (
              !innerItems[key].isMessage &&
              !innerItems[key].ishidden &&
              !this.validate(innerItems[key], setError)
            ) {
              valid = false
            }
          })
        })
      }
      return valid
    }
    const validator = inputItem.validator || {
      func: RequiredValidator,
      options: {
        errorName: inputItem.label,
      },
    }
    const validationResult = validator.func(inputItem.value, validator.options)
    setError && (inputItem.error = validationResult.error)

    return validationResult.valid
  }

  @action validateAll = (infoKey: string, setError: boolean) => {
    let valid = this.validateApplicant(infoKey, 'applicant', setError)
    if (this.hasCoapplicant()) {
      valid = valid && this.validateApplicant(infoKey, 'coapplicant', setError)
    }
    return valid
  }

  @action validateApplicant = (
    infoKey: string,
    type: typeApplicants,
    setError: boolean,
  ) => {
    if (!this.applicantKeyInfoMap[type][infoKey]) {
      return true
    }
    const storeKeys = this.applicantKeyInfoMap[type][infoKey].storeKeys

    let valid = true
    storeKeys.forEach(storeKey => {
      const input = this.get(storeKey, type)
      if (!input.isMessage && !input.ishidden && !this.validate(input, setError)) {
        valid = false
      }
    })

    return valid
  }

  getConfig = () => {
    return parseToConfig(
      toJS(this.inputStateMap.applicant),
      this.hasCoapplicant() ? toJS(this.inputStateMap.coapplicant) : null,
    )
  }
}
