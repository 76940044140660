import { tx } from '~libs/i18n'

export type IViewStoreInfo = {
  title?: string
  title_co?: string
  storeKeys: string[]
}

export const keyInfoMapApplicant: { [key: string]: IViewStoreInfo } = {
  initialInfo: {
    storeKeys: [
      'collateral.address.zip',
      'collateral.estimated_market_price',
      'collateral.mortgage.amount',
      'has_coapplicant',
      'applicant.employment.type',
      'applicant.income.monthly_gross_income',
    ],
  },
  applicant: {
    title: tx('application.result_input.title_about_applicant'),
    storeKeys: [
      'applicant.pidn',
      'applicant.name',
      'has_coapplicant_number',
      'applicant.civil_status',
      'applicant.has_children_under_18',
      'applicant.number_children_under_18',
      'applicant.mobile',
      'applicant.email',
      'applicant.is_invited',
      'applicant.invitation_code',
    ],
  },
  employment: {
    title: tx('application.result_input.title_employment_applicant'),
    storeKeys: [
      'applicant.employment.type',
      'applicant.employment.employer',
      'applicant.employment.role',
      'applicant.income.monthly_gross_income',
      'applicant.income.income_in_sek',
      'applicant.income.income_in_sek_false_message',
      'applicant.pep',
      'applicant.pep_true_message',
    ],
  },
  collateral: {
    title: tx('application.result_input.title_collateral_applicant'),
    title_co: tx('application.result_input.title_collateral_coapplicant'),
    storeKeys: [
      'collateral.address.street',
      'collateral.address.zip',
      'collateral.address.city',
      'collateral.estimated_market_price',
      'collateral.type',
      'collateral.property_id',
      'collateral.apartment_number',
      'collateral.housing_society',
      'collateral.monthly_cost',
      'collateral.number_rooms',
      'collateral.living_area',
    ],
  },
  debt: {
    title: tx('application.result_input.title_debt_applicant'),
    storeKeys: [
      'collateral.mortgage.amount',
      'collateral.mortgage.bank',
      'collateral.mortgage.monthly_amortization',
      'applicant.has_other_loans',
      'applicant.other_loans',
      'applicant.has_other_properties',
      'applicant.other_properties',
    ],
  },
}

export const keyInfoMapCoapplicant: { [key: string]: IViewStoreInfo } = {
  applicant: {
    title: tx('application.result_input.title_about_coapplicant'),
    storeKeys: [
      'applicant.pidn',
      'applicant.name',
      'applicant.civil_status',
      'applicant.has_children_under_18',
      'applicant.number_children_under_18',
      'applicant.mobile',
      'applicant.email',
    ],
  },
  employment: {
    title: tx('application.result_input.title_employment_coapplicant'),
    storeKeys: [
      'applicant.employment.type',
      'applicant.employment.employer',
      'applicant.employment.role',
      'applicant.income.monthly_gross_income',
      'applicant.income.income_in_sek',
      'applicant.income.income_in_sek_false_message',
      'applicant.pep',
      'applicant.pep_true_message',
    ],
  },
  debt: {
    title: tx('application.result_input.title_debt_coapplicant'),
    storeKeys: [
      'applicant.has_other_loans',
      'applicant.other_loans',
      'applicant.has_other_properties',
      'applicant.other_properties',
    ],
  },
}
