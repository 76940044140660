import React, { Component } from 'react'
import Lottie from 'lottie-react-web'
import { Container, Row, Col, Div, Responsive } from '~components'
import { IThreeIconTextsSection } from '~models'
import styles from './styles.module.scss'
import { Animations } from '~libs/assets'

export interface ThreeIconTextsSectionProps {
  section: IThreeIconTextsSection
}

export class ThreeIconTextsSection extends Component<ThreeIconTextsSectionProps> {
  render() {
    const { texts } = this.props.section
    const icons = [Animations.checkmark, Animations.lock, Animations.video_library]

    return (
      <section className={`d-flex flex-column justify-content-baseline`}>
        <Container main className={styles.container}>
          <Row>
            {texts.map((item, index) => (
              <Col
                className={'d-flex flex-column justify-content-start align-items-center'}
                key={item.title}
                md={4}
              >
                <Div className={styles.icon}>
                  <Lottie
                    className={styles.icon}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: icons[index],
                    }}
                    height="82px"
                    width="52px"
                  />
                </Div>
                <h3 className={`text-center ${styles.smallHeading}`}>{item.title}</h3>
                <p className={`body-regular text-center ${styles.smallText}`}>
                  <Responsive>
                    {item.text_xs}
                    {item.text}
                  </Responsive>
                </p>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    )
  }
}
