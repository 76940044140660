import React, { Component } from 'react'
import styles from './styles.module.scss'
import { Link } from 'gatsby'
import { Icons } from '~libs/assets'

export interface HeaderLogoProps {}
export interface HeaderLogoState {}

export class HeaderLogo extends Component<HeaderLogoProps, HeaderLogoState> {
  render() {
    return (
      <Link to={'/'}>
        <img src={Icons.hemma_logo} className={styles.logo_name} />
      </Link>
    )
  }
}
