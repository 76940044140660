import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'gatsby'
import { ApplicationUIStore, ApplicationQueueStore } from '~stores'
import { Button, Responsive } from '~components'
import { IActionButton } from '~models'

export interface ActionButtonProps extends React.HTMLProps<HTMLButtonElement> {
  styleKey?: string
  rounded?: boolean
  disabled?: boolean
  big?: boolean
  actionButton: IActionButton
  applicationUIStore?: ApplicationUIStore
  applicationQueueStore?: ApplicationQueueStore
  symbol?
}

@inject('applicationUIStore', 'applicationQueueStore')
@observer
export class ActionButton extends Component<ActionButtonProps> {
  render() {
    const {
      applicationUIStore,
      applicationQueueStore,
      actionButton,
      styleKey,
      rounded,
      disabled,
      big,
      className,
      symbol,
    } = this.props
    const buttonProps = { styleKey, rounded, disabled, big, className }
    const { button_title, button_title_xs, type, page_link } = actionButton

    let href = null
    let onClick = null

    if (button_title) {
      switch (type) {
        case 'link_to_page':
          href = `/${page_link.post_name}`
          break
        case 'my_pages':
          href = '/privat'
          break
        case 'open_application':
          onClick = applicationUIStore.open
          break

        case 'open_application_queue':
          onClick = applicationQueueStore.open
          break

        case 'open_application_invited':
          onClick = applicationQueueStore.openInvited
          break
      }

      const button = (
        <Button {...buttonProps} onClick={onClick}>
          <Responsive>
            {button_title_xs}
            {button_title}
          </Responsive>
          {symbol}
        </Button>
      )

      if (href != null) {
        return <Link to={href}>{button}</Link>
      }

      return button
    }
    return null
  }
}
