import { observable, action, toJS } from 'mobx'
import FileSaver from 'file-saver'
import { HemmaApi, TPaginatedList } from '~libs/api/HemmaApi'
import {
  NumberValidator,
  BooleanValidator,
  EmailValidator,
  PhoneValidator,
} from '~libs/validators'
import { AccountNumberFormatter, PhoneFormatter } from '~libs/formatters'
import { tx } from '~libs/i18n'
import { IInputItem } from './ApplicationUIStore/props'
import { Invoice, Document, Quote } from '~libs/api/models'
import { Store } from './Store'
import { delay } from '~libs/utils'

export interface ListItems<T> {
  page: number
  limit: number
  items: T[]
  apiFunc: TPaginatedList
  status: 'idle' | 'loading' | 'error'
  hasMoreItems: boolean
  meta: {
    total: number
    total_read?: number
  }
}

export type IItems = { [key: string]: IInputItem | IInputItem[] }

const defaultAcceptQuote: IItems = {
  accountNumber: {
    value: '',
    label: tx('misc.your_account_number'),
    placeholder: tx('misc.account_number'),
    type: 'tel',
    formatter: AccountNumberFormatter,
    maxLength: 19,
    autoComplete: 'cc-number',
    validator: {
      func: NumberValidator,
      options: {
        minDigits: 16,
        errorName: tx('misc.account_number'),
      },
    },
  },
  email: [
    {
      value: '',
      label: tx('misc.email'),
      type: 'email',
      autoComplete: 'off',
      validator: {
        func: EmailValidator,
        options: { errorName: tx('misc.email') },
      },
    },
  ],
  mobile: [
    {
      value: '',
      label: tx('misc.mobile'),
      type: 'tel',
      autoComplete: 'off',
      formatter: PhoneFormatter,
      maxLength: 18,
      validator: {
        func: PhoneValidator,
        options: { errorName: tx('misc.mobile') },
      },
    },
  ],
  acceptAgreement: {
    value: false,
    validator: {
      func: BooleanValidator,
      options: {
        value: true,
        errorName: '',
      },
    },
  },
}

const defaultInviteFriends: IInputItem[] = [
  {
    value: '',
    label: tx('my_pages.invite_friend.friend_email'),
    type: 'email',
    autoComplete: 'off',
    validator: {
      func: EmailValidator,
      options: { errorName: tx('misc.email') },
    },
  },
]

const defaultListItems = {
  invoice: <ListItems<Invoice>>{
    page: 0,
    limit: 3,
    items: [],
    apiFunc: 'getInvoices',
    status: 'idle',
    hasMoreItems: true,
    meta: {},
  },
  document: <ListItems<Document>>{
    page: 0,
    limit: 3,
    items: [],
    apiFunc: 'getDocuments',
    status: 'idle',
    hasMoreItems: true,
    meta: {},
  },
}

type TRequestState = 'idle' | 'pending' | 'success' | 'error'

export class MyPagesStore {
  rootStore: Store
  api: HemmaApi

  @observable
  acceptQuotes: { [key: string]: IItems } = {}

  @observable
  inviteFriends = defaultInviteFriends

  @observable
  listItems = defaultListItems

  @observable
  updateQuoteAutogiroState: {
    [key: string]: TRequestState
  } = {}

  @observable
  updateQuoteContactState: {
    [key: string]: TRequestState
  } = {}

  @observable
  signQuoteState: { [key: string]: TRequestState } = {}

  @observable
  signQuoteError: { [key: string]: string } = {}

  @observable
  sendFriendInvitesState: TRequestState = 'idle'

  constructor(rootStore: Store, api: HemmaApi) {
    this.rootStore = rootStore
    this.api = api
  }

  setQuotes = (quotes: Quote[]) => {
    quotes &&
      quotes.forEach(quote => {
        if (!this.acceptQuotes[quote.id]) {
          this.acceptQuotes[quote.id] = defaultAcceptQuote

          const mobQuote = this.acceptQuotes[quote.id]

          if (quote.contact) {
            if (quote.contact.email_addresses.length) {
              mobQuote.email = quote.contact.email_addresses.map(email => ({
                ...defaultAcceptQuote.email[0],
                value: email,
              }))
            }
            if (quote.contact.phone_numbers.length) {
              mobQuote.mobile = quote.contact.phone_numbers.map(email => ({
                ...defaultAcceptQuote.mobile[0],
                value: defaultAcceptQuote.mobile[0].formatter(email),
              }))
            }
          }

          const accountNumberField = mobQuote.accountNumber as IInputItem
          accountNumberField.value = accountNumberField.formatter(quote.account || '')
        }
      })
  }

  resetStore = () => {
    this.inviteFriends = defaultInviteFriends
    this.listItems = defaultListItems
    this.acceptQuotes = {}
  }

  @action
  downloadDocument = async (document: Document) => {
    const result = await this.api.downloadDocument(document.id)
    if (result.ok) {
      FileSaver.saveAs(result.data, document.name)
    }
  }

  @action
  loadItems = async (key: string) => {
    const listItem: ListItems<any> = this.listItems[key]
    if (!listItem || listItem.status === 'loading') return

    listItem.page++
    listItem.status = 'loading'
    const response = await this.api[listItem.apiFunc](
      `page=${listItem.page}&limit=${listItem.limit}`,
    )
    if (response.ok) {
      listItem.status = 'idle'
      listItem.items.push(...response.data.data)
      listItem.hasMoreItems = listItem.items.length < response.data.meta.total
      listItem.meta = response.data.meta
    } else {
      listItem.status = 'error'
    }
  }

  @action
  addInviteFriend = () => {
    const copy = toJS(this.inviteFriends[0])
    this.inviteFriends.push({ ...copy, value: '', error: null })
  }

  @action
  removeInviteFriend = (index: number) => {
    if (this.inviteFriends.length <= 1) {
      return
    }
    this.inviteFriends.splice(index, 1)
  }

  addToQuoteArray = (quote: Quote, key: string) => {
    const field = this.acceptQuotes[quote.id][key] as IInputItem[]
    field.push(defaultAcceptQuote[key][0])
  }

  removeFromQuoteArray = (quote: Quote, index: number, key: string) => {
    const field = this.acceptQuotes[quote.id][key] as IInputItem[]
    if (field.length <= 1) {
      return
    }
    field.splice(index, 1)
  }

  @action
  updateQuoteAutogiro = async (quote: Quote, onSuccess?: () => void) => {
    const accountNumberField = this.acceptQuotes[quote.id].accountNumber as IInputItem
    if (!this.validateItem(accountNumberField, true)) {
      return
    }
    const accountNumber = accountNumberField
      .formatter(accountNumberField.value, true)
      .toString()
    this.updateQuoteAutogiroState[quote.id] = 'pending'
    const result = await this.api.putQuoteAutogiro(quote.id, accountNumber)
    if (result.ok) {
      quote.account = accountNumber
      this.updateQuoteAutogiroState[quote.id] = 'success'
      onSuccess && onSuccess()
    } else {
      this.updateQuoteAutogiroState[quote.id] = 'error'
    }
  }

  @action
  updateQuoteContact = async (quote: Quote, onSuccess?: () => void) => {
    const emailField = this.acceptQuotes[quote.id].email as IInputItem[]
    const mobileField = this.acceptQuotes[quote.id].mobile as IInputItem[]
    if (!this.validateItems([...emailField, ...mobileField], true)) {
      return
    }

    const emails = emailField.map(field => field.value)
    const numbers = mobileField.map(field => field.value)

    this.updateQuoteContactState[quote.id] = 'pending'
    const result = await this.api.putQuoteContact(quote.id, emails, numbers)
    if (result.ok) {
      quote.contact.email_addresses = emails
      quote.contact.phone_numbers = numbers
      this.updateQuoteContactState[quote.id] = 'success'
      onSuccess && onSuccess()
    } else {
      this.updateQuoteContactState[quote.id] = 'error'
    }
  }

  @action
  signQuote = async (quote: Quote) => {
    this.signQuoteState[quote.id] = 'pending'
    const result = await this.api.postQuoteSign(quote.id)
    if (result.ok) {
      this.verifySignQuote(quote)
    } else {
      this.signQuoteState[quote.id] = 'error'
      this.signQuoteError[quote.id] = 'error: ' + result.status
    }
  }

  @action verifySignQuote = async (quote: Quote) => {
    await delay(2000)
    while (this.signQuoteState[quote.id] === 'pending') {
      const result = await this.api.getQuoteSign(quote.id)

      if (result.ok) {
        if (result.status === 200) {
          this.signQuoteState[quote.id] = 'success'
          //this.rfqView[key] = result.data as RfqView
          return
        } else {
          console.log('verifySignQuote', result.data)
          await delay(1000)
        }
      } else {
        this.signQuoteError[quote.id] = 'error: ' + result.status
        this.signQuoteState[quote.id] = 'error'
        return
      }
    }
  }

  @action sendFriendInvites = async () => {
    if (!this.validateItems(this.inviteFriends, true)) {
      return
    }

    const invitations = this.inviteFriends.map(field => field.value)
    const { name, email } = this.rootStore.authStore.currentUser
    this.sendFriendInvitesState = 'pending'
    const result = await this.api.putInvite({
      name,
      email,
      invitations,
    })
    if (result.ok) {
      this.sendFriendInvitesState = 'success'
    } else {
      this.sendFriendInvitesState = 'error'
    }
  }

  @action resetFriendInvites = async () => {
    this.inviteFriends = defaultInviteFriends
    this.sendFriendInvitesState = 'idle'
  }

  @action
  validateItem = (item: IInputItem, setError: boolean) => {
    if (item.validator) {
      const validationResult = item.validator.func(item.value, item.validator.options)
      if (setError) {
        item.error = validationResult.error
      }
      return validationResult.valid
    }
    return true
  }

  @action
  validateItems = (items: IInputItem[], setError: boolean) => {
    let valid = true
    items &&
      items.some(item => {
        if (!this.validateItem(item, setError)) {
          valid = false
          if (!setError) {
            return true
          }
        }
        return false
      })
    return valid
  }

  @action
  validateState = (state: IItems, setError: boolean) => {
    let valid = true

    Object.keys(state).some(key => {
      const item = state[key] as any
      let validatorFunc: any = this.validateItem
      if (typeof item.splice !== 'undefined') {
        validatorFunc = this.validateItems
      }
      if (!validatorFunc(item, setError)) {
        valid = false
        if (!setError) {
          return true
        }
      }
      return false
    })
    return valid
  }

  isInviteFriendsValid = () => {
    const invalid = this.inviteFriends.find(item => !this.validateItem(item, false))
    return !invalid
  }
}
