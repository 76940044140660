import React, { Component } from 'react'
import { Container } from '~components'
import { ITestimonial } from '~models'
import Img from 'gatsby-image'
import styles from './styles.module.scss'

export interface TestimonialSectionProps {
  section: ITestimonial
}

export class TestimonialSection extends Component<TestimonialSectionProps> {
  renderImages = () => {
    const { section } = this.props

    return section.testimonials.map((testimonial, index) => {
      const image = testimonial.image.localFile.childImageSharp.large
      const style = {
        width: image.presentationWidth,
      }

      return (
        <a
          className={styles.image_wrapper}
          key={'testimonial_' + index}
          href={testimonial.link}
        >
          <Img className={styles.image} style={style} fluid={image} />
        </a>
      )
    })
  }
  render() {
    return (
      <section className={styles.section}>
        <p className={styles.title}>Läs om Hemma</p>
        <Container className={styles.wrapper}>{this.renderImages()}</Container>
      </section>
    )
  }
}
