import { MoneyFormatter } from '~libs/formatters'
import { IInputState } from '../props'
import { NumberValidator } from '~libs/validators'
import { nbsp } from '~libs/constants'
import { tx } from '~libs/i18n'

export const mortgage: IInputState = {
  amount: {
    label: tx('application.models.mortgage.amount'),
    placeholder: '5 000 000',
    text: tx('application.models.mortgage.amount_text', { nbsp }),
    overviewValue: '{value} kr',
    type: 'tel',
    formatter: MoneyFormatter,
    maxLength: 10,
    validator: {
      func: NumberValidator,
      options: {
        minValue: 100000,
        maxValue: 20000000,
        errorName: 'lån',
      },
    },
  },
  monthly_amortization: {
    label: tx('application.models.mortgage.monthly_amortization'),
    overviewValue: '{value} kr',
    type: 'tel',
    formatter: MoneyFormatter,
    maxLength: 9,
    validator: {
      func: NumberValidator,
      options: {
        minValue: 0,
        maxValue: 1000000,
        errorName: 'amortering',
      },
    },
  },
  bank: {
    label: tx('application.models.mortgage.bank'),
    type: 'text',
  },
}
