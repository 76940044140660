import { MoneyFormatter } from '~libs/formatters'
import { nbsp } from '~libs/constants'
import { IInputState } from '../props'
import { tx } from '~libs/i18n'
import { BooleanValidator } from '~libs/validators'

export const income: IInputState = {
  monthly_gross_income: {
    label: tx('application.models.income.monthly_gross_income'),
    placeholder: '50 000',
    text: tx('application.models.income.monthly_gross_income_text', { nbsp }),
    overviewValue: '{value} kr',
    type: 'tel',
    formatter: MoneyFormatter,
    maxLength: 7,
  },
  income_in_sek: {
    label: tx('application.models.income.income_in_sek'),
    label_secondary: tx('application.models.income.income_in_sek_secondary'),
    type: 'boolean',
    validator: {
      func: BooleanValidator,
      options: { value: 'Ja', errorName: tx('application.models.applicant.pep') },
    },
    triggers: [
      {
        value: 'Nej',
        keys: ['income_in_sek_false_message'],
      },
    ],
  },
  income_in_sek_false_message: {
    hidden: true,
    isMessage: true,
    text: tx('application.models.income.income_in_sek_error'),
  },
}
