import { IInputState, IInputItem } from './props'
import { Config } from '~libs/api/models'

const stringToBoolean = value => {
  return typeof value === 'string' ? value.toLowerCase() === 'ja' : value
}

const formattedValue = item => {
  return item.formatter
    ? item.formatter(item.value, true)
    : item.type === 'boolean'
    ? stringToBoolean(item.value)
    : item.value
}

const isObject = item =>
  typeof item === 'object' &&
  ['object', 'undefined'].includes(typeof item.type) &&
  !item.value &&
  !item.isMessage

const getValue = (key: string, item: IInputItem) => {
  if (key.startsWith('has_')) return undefined
  if (typeof item === 'object') {
    return isObject(item) || item.ishidden || item.isArray
      ? undefined
      : formattedValue(item)
  }
  return item
}

const parseObject = obj => {
  const result = {}
  Object.keys(obj).forEach(key => {
    if (key !== 'storepath') {
      const item = obj[key]
      if (isObject(item)) {
        result[key] = parseObject(item)
      } else {
        const value = getValue(key, item)
        if (typeof value !== 'undefined') {
          result[key] = value
        }
      }
    }
  })

  return result
}

const parseArray = (item, appendTo = [], handleItems?) => {
  if (item.ishidden) {
    return appendTo
  }

  let items = item.value.map(parseObject)
  handleItems && handleItems(items)

  return [...appendTo, ...items]
}

export const parseToConfig: (
  mainApplicantState: IInputState,
  coApplicantState?: IInputState,
) => Config = (mainApplicantState: IInputState, coApplicantState?: IInputState) => {
  let applicant: Config = parseObject(mainApplicantState.applicant)
  applicant.other_debts = parseArray(
    mainApplicantState.applicant['other_loans'],
    applicant.other_debts,
  )
  applicant.other_debts = parseArray(
    mainApplicantState.applicant['other_properties'],
    applicant.other_debts,
    items => items.forEach(item => (item.type = 'mortgage')),
  )

  const collateral: Config = parseObject(mainApplicantState.collateral)

  const result = {
    applicant,
    collateral,
  }

  if (coApplicantState) {
    let applicant: Config = parseObject(coApplicantState.applicant)
    applicant.other_debts = parseArray(
      coApplicantState.applicant['other_loans'],
      applicant.other_debts,
    )
    applicant.other_debts = parseArray(
      coApplicantState.applicant['other_properties'],
      applicant.other_debts,
      items => items.forEach(item => (item.type = 'mortgage')),
    )
    result['coapplicant'] = applicant
  }

  return result
}
