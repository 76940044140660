export type TypeRequestAnimationFrame = (FrameRequestCallback) => number

export const getRequestAnimationFrame: () => TypeRequestAnimationFrame = () => {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    (<any>window).mozRequestAnimationFrame ||
    (<any>window).oRequestAnimationFrame ||
    (<any>window).msRequestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60)
    }
  )
}

export const runAnimationFrame = (func: FrameRequestCallback) => {
  return getRequestAnimationFrame()(func)
}
