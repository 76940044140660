import React, { Component } from 'react'
import { screenWidthMd } from '~libs/constants'

export interface ResponsiveProps extends React.HTMLProps<HTMLElement> {}
export interface ResponsiveState extends React.HTMLProps<HTMLElement> {
  isWindowSmall: boolean
}

export class Responsive extends Component<ResponsiveProps, ResponsiveState> {
  constructor(props) {
    super(props)

    this.state = {
      isWindowSmall: false,
    }

    this.windowWidthChanged = this.windowWidthChanged.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowWidthChanged)
    this.windowWidthChanged()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.windowWidthChanged)
  }

  windowWidthChanged() {
    const width = window.innerWidth
    const { isWindowSmall } = this.state
    if (
      (isWindowSmall && width >= screenWidthMd) ||
      (!isWindowSmall && width < screenWidthMd)
    ) {
      this.setState({ isWindowSmall: !isWindowSmall })
    }
  }
  render() {
    const { isWindowSmall } = this.state
    const { children, className, ...rest } = this.props

    return isWindowSmall
      ? children[0] // First string might be empty, while the next one exists
        ? children[0]
        : children[1]
      : children[1]
      ? children[1]
      : children[0]
  }
}
