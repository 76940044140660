import React, { Component } from 'react'
import { Slider as SSlider, Rail, Handles, Tracks } from 'react-compound-slider'
import { Handle, Track } from './components'
import styles from './styles.module.scss'

const sliderStyle = {
  position: 'relative',
  width: '100%',
}

const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 2,
  borderRadius: 1,
  cursor: 'pointer',
  backgroundColor: '#edeff6', //#pale-grey
}

export interface SliderCompProps {
  label: string
  initialValue: number
  min?: number
  max?: number
  onChange: Function
  valueSymbol?: string
  step?: number
  valueFormatter?: Function
}
export interface SliderCompState {
  values: number[]
  update: number[]
}

class Slider extends Component<SliderCompProps, SliderCompState> {
  constructor(props) {
    super(props)

    this.state = {
      values: [this.props.initialValue],
      update: [this.props.initialValue],
    }
  }

  domain = [this.props.min, this.props.max]
  defaultValues = this.props.initialValue

  onUpdate = update => {
    this.props.onChange(update)
    this.setState({ update })
  }

  onChange = values => {
    this.props.onChange(values)
    this.setState({ values })
  }

  render() {
    const {
      state: { values, update },
    } = this

    const { label, valueSymbol, step, valueFormatter } = this.props
    return (
      <div className={styles.slider}>
        <p className={`body-regular ${styles.sliderLabel}`}>{label}</p>{' '}
        <p className={`label ${styles.sliderLabel} d-md-none d-inline-block`}>
          {valueFormatter ? valueFormatter(update) : update}
          {valueSymbol && valueSymbol}
        </p>
        <SSlider
          mode={1}
          step={step}
          domain={this.domain}
          rootStyle={sliderStyle}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={this.domain}
                    getHandleProps={getHandleProps}
                    isActive
                    valueSymbol={valueSymbol}
                    valueFormatter={valueFormatter}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </SSlider>
      </div>
    )
  }
}

export default Slider
