export default {
  translation: {
    validators: {
      missing: 'Ange {{- name, lower}}',
      number_low: '{{- name, capital}} måste vara minst {{value}} kr',
      number_high: '{{- name, capital}} får vara max {{value}} kr',
      digit: 'Det saknas en siffra i ditt {{- name, lower}}',
      digit_plural: 'Det saknas {{count}} siffror i ditt {{- name, lower}}',
      digit_to_many: '{{count}} siffra för mycket i ditt {{- name, lower}}',
      digit_to_many_plural: '{{count}} för många siffror i ditt {{- name, lower}}',
      digits_missing: 'Det saknas siffror i ditt {{- name, lower}}',
      digits_to_many: 'Det är för många siffror i ditt {{- name, lower}}',
      email: 'Din angivna email är inte giltig',
      pidn: 'Ditt angivna personummer är inte giltigt',
      invalid: 'Din angivna {{- name, lower}} är inte giltig',
    },
    application: {
      models: {
        applicant: {
          name: 'Namn (För och Efternamn)',
          pidn: 'Personnummer',
          mobile: 'Telefonnummer',
          email: 'E-mail',
          civil_status: 'Civilstånd',
          has_children_under_18: 'Har du barn under 18?',
          number_children_under_18: 'Antal barn under 18',
          pep: 'Är du eller någon du känner en politiskt exponerad person (pep)?',
          pep_error: 'Pep Felmeddelande (TODO)',
          accept_end_user_agreement:
            'Jag försäkrar att de uppgifter som lämnats i ansökan är riktiga och fullständiga, samt att jag söker lånet för min egen räkning. Jag är medveten om att en kreditprövning nu kommer ske och medger att kreditupplysning inhämtas från kreditupplysningsföretag och att information gällande ställd säkerhet inhämtas från Lantmäteriet eller bostadsrättsföreningen.\nLäs mer',
          has_other_loans: 'Har du andra lån och krediter?',
          has_other_loans_secondary: 'Har din medsökande andra lån och krediter?',
          has_other_properties: 'Äger du andra fastigheter?',
          has_other_properties_secondary: 'Äger din medsökande andra fastigheter?',
          is_invited: 'Har du blivit inbjuden av en vän?',
          invitation_code:
            'Om du har blivit inbjuden av en vän så hittar du referenskoden i din mailbox.',
          invitation_code_preset:
            'Vi ser att du har blivit inbjuden av en vän med referenskod:',
        },
        coapplicant: {
          has_coapplicant_number: 'Antal låntagare',
          has_coapplicant_number_text: 'Jag har lånet {?}',
          pep:
            'Är din medsökande eller någon din medsökare känner en politiskt exponerad person (pep)?',
        },
        address: {
          street: 'Adress',
          zip: 'Postnummer',
          city: 'Ort',
        },
        collateral: {
          estimated_market_price: 'Bostadens värde',
          estimated_market_price_text: 'Min bostad är värd\n{?}{{nbsp}}kr',
          type: 'Typ av boende',
          apartment_number: 'Lägenhetsnummer',
          number_rooms: 'Antal rum',
          living_area: 'Boyta',
          property_id: 'Fastighetsbeteckning',
          housing_society: 'Namn på bostadsrättsförening',
          monthly_cost: 'Månadsavgift',
        },
        debt: {
          type: 'Typ av lån',
          amount: 'Total låneskuld',
          monthly_payment: 'Vad amorterar du idag i månaden?',
          has_multiple_borrowers:
            'Finns det ytterligare låntagare till befintligt bolån?',
          number_borrowers: 'Antal låntagare',
          collateral_type: 'Typ av fastighet',
          collateral_monthly_cost: 'Månadsavgift/driftskostnad',
        },
        employment: {
          type: 'Sysselsättning',
          employer: 'Arbetsgivare',
          role: 'Yrkesroll',
        },
        income: {
          monthly_gross_income: 'Inkomst per månad före skatt',
          monthly_gross_income_text: 'Jag tjänar{{nbsp}}{?}\nkr/mån före skatt',
          income_in_sek: 'Får du din inkomst i SEK?',
          income_in_sek_secondary: 'Inkomst i SEK?',
          income_in_sek_error:
            'Hemma godkänner endast inkomster i svenska, norska eller danska kronor. Vid inkomster i norska eller danska kronor behöver vi göra en manuell bedömning och vi ber dig därför att kontakta oss för fortsatt hantering. Vänligen ring oss på 073 658 74 68.',
        },
        mortgage: {
          amount: 'Befintligt lån',
          amount_text: 'Mitt nuvarande lån\när på {?}{{nbsp}}kr',
          monthly_amortization: 'Vad amorterar du idag i månaden?',
          bank: 'Långivare',
        },
      },
      intro: {
        strapline: 'ANSÖK OM ATT FLYTTA DITT LÅN',
        title: 'Vi behöver lära känna dig och ditt hem lite bättre',
        button: 'Svara på några frågor',
      },
      suggestion: {
        strapline: 'TACK!',
        title: 'Vi tar fram ditt förslag',
      },
      result_input: {
        strapline: 'STEG {{step}} AV {{maxStep}}',
        title_about_applicant: 'Uppgifter om dig',
        title_about_coapplicant: 'Uppgifter om din medsökande',
        title_employment_applicant: 'Sysselsättning',
        title_employment_coapplicant: 'Din medsökandes sysselsättning',
        title_collateral_applicant: 'Uppgifter om ditt boende',
        title_collateral_coapplicant: 'Uppgifter om ert boende',
        title_debt_applicant: 'Dina lån',
        title_debt_coapplicant: 'Din medsökandes lån',
      },
      overview: {
        strapline: 'SNART KLAR',
        title: 'Kontrollera och signera',
        sign_title: 'Signera med BankID',
        sign_title_coapplicant: 'Identifiera er med BankID',
        sign_title_coapplicant_secondary: 'Medsökande',
        sign_title_verifying: 'Starta appen för BankID',
        sign_title_timeout: 'Oops, tiden rann ut.',
        sign_title_success: 'Signering lyckades (TODO)',
        sign_info: `Observera att när ni signerar nedan så godkänner ni endast att vi skickar en offert till er. \nLäs den i lugn och ro. Innan ni kan flytta ert lån behöver ni godkänna offerten ni får från oss med BankID.`,
        sign_info_verifying:
          'För att signera, öppna din BankID-applikation på din dator eller mobil och verifiera att du är du.',
        sign_info_timeout: 'Oroa dig inte - du kan prova att signera igen.',
        sign_info_success: 'Signering lyckades (TODO)',
        sign_info_link:
          'Skicka ett e-mail med en länk till detta formulär. Länken är giltig i 24 timmar.',
        sign_button: 'Signera med BankID',
        sign_button_timeout: 'Prova att signera igen',
        sign_button_link: 'Skicka länk',

        sign_coapplicant_no_sign: 'Min medsökande kan inte signera just nu',
      },
      finish: {
        strapline: 'DIN ANSÖKAN ÄR KLAR',
        title: 'Tack för din ansökan! Vi hör av oss snart.',
        button: 'Tillbaka till sidan',
      },
      interest_bar: {
        your_new_cost: 'Din nya ränta:',
        you_will_save: 'Du sparar',
      },
    },
    application_queue: {
      queue_screen: {
        strapline: 'Just nu är det lite kö',
        title: 'Vad kul att du vill flytta ditt bolån till Hemma!',
        text:
          'Just nu är det många som vill få en lägre ränta. Därför har vi byggt ett kösystem. Fyll i din e-mail så hör vi av oss när det är din tur.',
        i_have_invitation: 'Jag har en inbjudan',
        button: 'Ställ dig i kö',
        already_queued_error: 'Du står redan i kö!',
      },
      invite_screen: {
        strapline: 'Du står nu i kö',
        title: 'Tack! Du står nu i kö.',
        text: `Vi ser fram emot att ge dig ett billigare bolån och våra handläggare jobbar dag och natt.
Vi hör av oss så fort det är din tur!`,
        add_button: 'Lägg till fler adresser till vänner',
        back_button: 'Tillbaka till förstasidan',
        invite_label: 'Bjud in en vän för att hamna högre i kön',
      },
      invite_screen_2: {
        strapline: 'Du står nu i kö',
        title: 'Tack! Nu står du längre fram i kön.',
        text: `Tipsa en vän om Hemma och få en bättre köplats.`,
        add_button: 'Lägg till fler adresser till vänner',
        back_button: 'Tillbaka till förstasidan',
        invite_label: 'E-mail till din vän',
      },
      invited_screen: {
        strapline: 'Inbjudan',
        title: 'Har du blivit inbjuden? Fyll då bara i din kod',
        text:
          'Du hittar koden i din personliga inbjudan. Var vänlig och fyll i koden under för att fortsätta till ansökan. Du kommer därefter behöva svara på några korta frågor.',
        code_label: 'Din kod på inbjudan',
      },
      sorry_screen: {
        strapline: 'Inbjudan',
        title: 'Förlåt!',
        text:
          'Vi har väldigt många ansökningar just nu. Du behålller din plats överst i kön. Vi återkommer till dig snarast! Vill du ha ett mail eller sms när vi öppnar upp för nya lån fyll i nedan.',
      },
      gratitude_screen: {
        title: 'Tack, vi hör av oss snart!',
        back_button: 'Tillbaka till sidan',
      },
    },
    landing_page: {
      mortgage_calculator: {
        heading_left_column: 'Vad du betalar idag',
        heading_right_column: 'Vad du betalar med Hemma',
        button_show_more: 'Se hur vi räknat',
        button_show_less: 'Dölj',
        asterisk_text: '*Räntekostnaden är baserad på vår snittränta 0,79%',
        you_will_save: 'Du sparar:',
        your_new_cost: 'Din nya räntekostnad:',
        your_current_cost: 'Nuvarande räntekostnad:',
        slider_one_label: 'Vad har du för ränta idag?',
        slider_two_label: 'Hur stort lån har du?',
      },
      letter: {
        button_show_more: 'Läs mindre',
        button_show_less: 'Läs mer',
      },
    },
    login: {
      title: 'Logga in',
      button: 'Logga in',
    },
    my_pages: {
      quote: {
        title: 'Ditt erbjudande',
      },
      accept_quote: {
        direct_debit_title: 'Anmäl autogiro',
        contact_title: 'Kontaktuppgifter',
        contact_description: 'Fyll i adresserna dit du vill ha aviseringar.',
        final_title: 'Vad händer nu?',
        final_description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        sign_title: 'Signera med BankID',
        agreement: 'Jag har läst och godkänner villkoren',
        add_email: 'Lägg till fler adresser',
        add_mobile: 'Lägg till fler telefonnummer',
      },
      invite_friend: {
        title: 'Bjud in en vän och få nästa månad gratis',
        friend_email: 'E-mail till din vän',
        add_address: 'Lägg till fler adresser till vänner',
        title_success: 'Grattis nästa månad är gratis!',
        description_success: 'Bjud in fler vänner och få fler räntefria månader',
        button_success: 'Bjud in fler vänner',
      },
      debt: {
        current_debt: 'Aktuell skuld',
        due_date_at: 'Nästa faktura förfaller {{- date, moment_D MMM}}',
        term: 'Löptid',
      },
      invoice: {
        status_paid: 'Betald {{- date, moment_D MMM}}',
        status_unpaid: 'Förfaller {{- date, moment_D MMM}}',
        status_overdue: 'Försenad {{- date, moment_D MMM}}',
      },
      document_list_card: {
        title: 'Mina meddelanden',
        empty: 'Du har inga meddelanden än.',
      },
      invoice_list_card: {
        title: 'Mina avier',
        empty: 'Du har inga avier än.',
      },
      my_debt: {
        due_date_at: '{{- date, moment_D MMM YYYY}}',
      },
    },
    footer: {
      legal:
        '©2018 Hemma Bolån AB är ett konsumentkreditinstitut och\nstår under Finansinspektionens tillsyn. Alla rättigheter reserverade.',
    },
    misc: {
      sign_bankid: 'Signera med BankID',
      ok: 'Ok',
      apply: 'Ansök',
      choose: 'Välj',
      cancel: 'Avbryt',
      back: 'Tillbaka',
      move_on: 'Gå vidare',
      edit: 'Redigera',
      pidn: 'Personnummer',
      mobile: 'Telefonnummer',
      email: 'E-mail',
      password: 'Lösenord',
      login: 'Logga in',
      save: 'Spara',
      currency: 'kr',
      currency_per_year: 'kr/år',
      your_account_number: 'Ditt kontonummber',
      account_number: 'Kontonummer',
      account_owner: 'Kontoinnehavare',
      name: 'Namn',
      interest: 'Ränta',
      loan_amount: 'Lånebelopp',
      send: 'Skicka',
      term: 'Löptid',
      term_in_month: '{{count}} månad',
      term_in_month_plural: '{{count}} månader',
      reference_code: 'Referenskod',
    },
    '404': {
      title: `Hoppsan!
Här fanns ingenting.`,
    },
  },
}
