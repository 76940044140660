import React, { Component } from 'react'
import { Container, Row, Col } from '~components'
import { Mortgage } from '~libs/api/models'
import { MoneyFormatter } from '~libs/formatters'
import styles from './styles.module.scss'
import { tx } from '~libs/i18n'

export interface MyDebtSectionProps {
  mortgage: Mortgage
}

export class MyDebtSection extends Component<MyDebtSectionProps> {
  render() {
    const { mortgage } = this.props
    return (
      <section className={styles.wrapper}>
        <div className={styles.inner_wrapper}>
          <Container>
            <Row>
              <Col xs={12} md={3}>
                <p className={styles.title}>Din skuld:</p>
                <p className={styles.value}>{`${MoneyFormatter(
                  mortgage.current_amount,
                )} kr`}</p>
              </Col>
              <Col xs={12} md={2}>
                <p className={styles.title}>Din ränta:</p>
                <p className={styles.value}>{`${mortgage.interest_in_percent}%`}</p>
              </Col>
              <Col xs={12} md={3}>
                <p className={styles.title}>Din nästa betalning:</p>
                <p className={styles.value}>
                  {tx('my_pages.my_debt.due_date_at', {
                    date: mortgage.next_invoice_due_date,
                  })}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    )
  }
}
