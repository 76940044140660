import { IInputState } from '../props'
import { tx } from '~libs/i18n'

export const employment: IInputState = {
  type: {
    label: tx('application.models.employment.type'),
    text: 'Jag är {?}',
    type: 'select',
    selectableValues: [
      { name: 'Student', value: 'student' },
      { name: 'Pensionär', value: 'retired' },
      { name: 'Arbetssökande', value: 'unemployed' },
      { name: 'Tillsvidareanställd', value: 'indefinite' },
      { name: 'Projektanställd', value: 'temporary' },
    ],
    triggers: [
      {
        value: ['temporary', 'indefinite'],
        keys: ['employer', 'role'],
      },
    ],
  },
  employer: {
    ishidden: true,
    label: tx('application.models.employment.employer'),
    type: 'text',
    minLength: 0,
    maxLength: 100,
  },
  role: {
    ishidden: true,
    label: tx('application.models.employment.role'),
    type: 'text',
    minLength: 0,
    maxLength: 100,
  },
}
