import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Container, Row, ActionButton, Col, Div, Responsive } from '~components'
import { tx } from '~libs/i18n'
import { IMortgageCalculatorSection } from 'models'
import { ApplicationUIStore } from '~stores'
import { Icons } from '~libs/assets'
import { MoneyFormatter } from '~libs/formatters'
import styles from './styles.module.scss'
import Slider from './components/slider/Slider'

export interface MortgageCalculatorSectionProps {
  section: IMortgageCalculatorSection
  applicationUIStore?: ApplicationUIStore
}

export interface MortgageCalculatorSectionState {
  percentage: number
  mortgageSize: number
  isHowWeCountedOpen: boolean
}

@inject('applicationUIStore')
@observer
export class MortgageCalculatorSection extends Component<
  MortgageCalculatorSectionProps,
  MortgageCalculatorSectionState
> {
  constructor(props) {
    super(props)
    this.state = {
      percentage: 1.64,
      mortgageSize: 2500000,
      isHowWeCountedOpen: false,
    }
  }

  renderHeading(title: string) {
    /* Display heading only if screen larger than medium */
    return (
      <Row>
        <Col>
          <h3
            className={[styles.headingCalculator, 'd-none d-md-inline-block'].join(' ')}
          >
            {title}
          </h3>
        </Col>
      </Row>
    )
  }

  renderSliders() {
    const { percentage, mortgageSize } = this.state
    return (
      <Row className={styles.calculatorColContainer}>
        <Col>
          <Slider
            label={tx('landing_page.mortgage_calculator.slider_one_label')}
            initialValue={percentage}
            min={0}
            max={10}
            step={0.01}
            onChange={v => this.setState({ percentage: v })}
            valueSymbol={'%'}
          />
          <Slider
            label={tx('landing_page.mortgage_calculator.slider_two_label')}
            initialValue={mortgageSize}
            min={100000}
            max={20000000}
            onChange={v => this.setState({ mortgageSize: v })}
            step={50000}
            valueFormatter={v => MoneyFormatter(v.toString())}
          />
        </Col>
      </Row>
    )
  }

  renderCurrentInterestCost(showOnSmall: boolean) {
    const { percentage, mortgageSize } = this.state
    return (
      <Row
        className={[
          styles.calculatorColContainer,
          showOnSmall ? 'd-block d-md-none' : 'd-none d-md-inline-block',
        ].join(' ')}
      >
        <Col>
          <p className={['body-regular', styles.oneLine].join(' ')}>
            {tx('landing_page.mortgage_calculator.your_current_cost')}
          </p>{' '}
          <p className={['label', styles.oneLine, styles.uppercase].join(' ')}>
            {MoneyFormatter(
              Math.ceil(((percentage / 100) * mortgageSize) / 12).toString(),
            )}{' '}
            {tx('misc.currency')}
          </p>
        </Col>
      </Row>
    )
  }

  renderNewInterestCost(showOnSmall: boolean) {
    const { mortgageSize, percentage } = this.state
    /* Display row only if screen larger than medium */
    return (
      <Row
        className={[
          styles.calculatorColContainer,
          showOnSmall ? 'd-block d-md-none' : 'd-none d-md-block',
        ].join(' ')}
      >
        <Col>
          <p className={['body-regular', styles.oneLine].join(' ')}>
            {tx('landing_page.mortgage_calculator.your_new_cost')}
          </p>{' '}
          <p className={['label', styles.oneLine].join(' ')}>
            {MoneyFormatter(Math.ceil(((0.79 / 100) * mortgageSize) / 12).toString())} KR
          </p>{' '}
          <p
            className={[
              'label',
              styles.greenBubble,
              styles.uppercase,
              'd-none d-md-inline-block d-xl-none',
            ].join(' ')}
          >
            -
            {MoneyFormatter(
              (
                Math.ceil(((percentage / 100) * mortgageSize) / 12) -
                Math.ceil(((0.79 / 100) * mortgageSize) / 12)
              ).toString(),
            )}{' '}
            {tx('misc.currency')}
          </p>
        </Col>
      </Row>
    )
  }

  renderYouWillBeSaving(showMoreIsOpen: boolean) {
    const { percentage, mortgageSize } = this.state

    return (
      <Row className={styles.calculatorColContainer}>
        {/* Display row only if screen larger than medium */}
        <Col className={showMoreIsOpen ? 'd-block d-md-none' : 'd-none d-md-block'}>
          <p className={['body-regular', styles.oneLineLower, styles.margin0].join(' ')}>
            {tx('landing_page.mortgage_calculator.you_will_save')}
          </p>{' '}
          <p className={['label', styles.oneLineLower, styles.uppercase].join(' ')}>
            {MoneyFormatter(
              (
                100 *
                Math.ceil(
                  ((percentage / 100) * mortgageSize - (0.79 / 100) * mortgageSize) / 100,
                )
              ).toString(),
            )}{' '}
            {tx('misc.currency_per_year')}
          </p>
        </Col>

        {/* Show row only if screen smaller than medium */}
        {!showMoreIsOpen && (
          <Col className={['d-block d-md-none'].join(' ')}>
            <p className={['body-regular', styles.youWillSaveSmall].join(' ')}>
              {tx('landing_page.mortgage_calculator.you_will_save')}
              <br />
            </p>
            <h3 className={[styles.oneLine, styles.margin0, styles.uppercase].join(' ')}>
              {MoneyFormatter(
                (
                  100 *
                  Math.ceil(
                    ((percentage / 100) * mortgageSize - (0.79 / 100) * mortgageSize) /
                      100,
                  )
                ).toString(),
              )}{' '}
              {tx('misc.currency_per_year')}
            </h3>
          </Col>
        )}
      </Row>
    )
  }

  renderAsteriskText() {
    return (
      <Row className={styles.calculatorColContainer}>
        {/* Always show */}
        <Col>
          <p className={['body-small', styles.smallText].join(' ')}>
            {tx('landing_page.mortgage_calculator.asterisk_text')}
          </p>
        </Col>
      </Row>
    )
  }

  renderGetStartedButton() {
    const { applicationUIStore, section } = this.props
    const { action_button } = section
    return (
      <Row className={styles.calculatorColContainer}>
        <Col>
          <ActionButton
            rounded
            className={`w-100`}
            onClick={applicationUIStore.open}
            styleKey="button_space_between"
            actionButton={action_button}
            symbol={<img src={Icons.arrow_right} className={styles.arrow_right} />}
          />
        </Col>
      </Row>
    )
  }

  renderShowMoreButton() {
    const { isHowWeCountedOpen } = this.state
    return (
      /* Show line only if screen smaller than medium */
      !isHowWeCountedOpen ? (
        <Col className="d-block d-md-none">
          <p
            className={['link', styles.clickable].join(' ')}
            onClick={() => this.setState({ isHowWeCountedOpen: !isHowWeCountedOpen })}
          >
            {tx('landing_page.mortgage_calculator.button_show_more')}{' '}
            <img src={Icons.arrow_down} className={styles.arrow_down} />{' '}
          </p>
        </Col>
      ) : (
        <Col className={[styles.howWeCountedOpenBox, 'd-block d-md-none'].join(' ')}>
          <p
            className={['link', styles.clickable, styles.margin0].join(' ')}
            onClick={() => this.setState({ isHowWeCountedOpen: !isHowWeCountedOpen })}
          >
            {tx('landing_page.mortgage_calculator.button_show_less')}{' '}
            <img src={Icons.arrow_down} className={styles.arrow_up} />{' '}
          </p>
        </Col>
      )
    )
  }

  render() {
    const { percentage, mortgageSize, isHowWeCountedOpen } = this.state
    const { section } = this.props
    const { text, text_xs, strapline } = section

    return (
      <section>
        <Container centered main className={styles.beigeContainer}>
          <Div className={styles.whiteBox} />
          {/* This covers the bottom of the beige container with a white strip. */}
          <Row>
            <p className="label strapline">{strapline}</p>
          </Row>

          {/* --------------------------------------------------------
           *
           *       The headings and texts above the calculator
           *
           * --------------------------------------------------------- */}

          <Row>
            <h2 className="text-center">
              <br className={'d-inline-block d-md-none'} />
              {tx('misc.save')}{' '}
              {MoneyFormatter(
                (
                  100 *
                  Math.ceil(
                    ((percentage / 100) * mortgageSize - (0.79 / 100) * mortgageSize) /
                      100,
                  )
                ).toString(),
              )}{' '}
              {tx('misc.currency_per_year')}*
            </h2>
          </Row>
          <Row>
            <p
              className={['body-regular', styles.center, styles.beigeBreadText].join(' ')}
            >
              <Responsive>
                {text_xs}
                {text}
              </Responsive>
            </p>
          </Row>

          {/* --------------------------------------------------------
           *
           *       The calculator
           *
           * --------------------------------------------------------- */}

          <Row
            className={[
              styles.calculatorContainerSize,
              styles.calculatorContainer,
              'no-gutters',
            ].join(' ')}
          >
            {/* Left column of the calculator */}
            <Col className={styles.calculatorCol} sm={12} md={6}>
              {this.renderHeading(
                tx('landing_page.mortgage_calculator.heading_left_column'),
              )}
              {this.renderSliders()}
              {this.renderCurrentInterestCost(false)}
            </Col>

            {/* Right column of the calculator */}
            <Col
              sm={12}
              md={6}
              className={[styles.calculatorCol, styles.blueBackground].join(' ')}
            >
              {this.renderHeading(
                tx('landing_page.mortgage_calculator.heading_right_column'),
              )}
              {this.renderNewInterestCost(false)}
              {this.renderYouWillBeSaving(false)}
              {this.renderAsteriskText()}
              {this.renderGetStartedButton()}

              {isHowWeCountedOpen && (
                <>
                  {this.renderCurrentInterestCost(true)}
                  {this.renderNewInterestCost(true)}
                  {this.renderYouWillBeSaving(true)}
                </>
              )}
              {this.renderShowMoreButton()}
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}
