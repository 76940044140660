import React, { Component } from 'react'
import { IHeadSection } from '~models'
import { ActionButton, Col, Container, Div, ImgBundle, Row } from '~components'
import styles from './styles.module.scss'

export interface HeadSectionProps {
  section: IHeadSection
}

export class HeadSection extends Component<HeadSectionProps> {
  render() {
    const {
      title,
      text,
      button,
      button_xs,
      images,
      background_color,
      action_button,
    } = this.props.section
    return (
      <section className={styles.section} style={{ backgroundColor: background_color }}>
        <ImgBundle images={images} className={styles.backgroundImage} />
        <Div
          className={[styles.absolute, 'd-flex flex-column justify-content-start'].join(
            ' ',
          )}
        >
          <Container main>
            <Row>
              <Col className={styles.headingContainer} sm={10} md={11}>
                <h1>{title}</h1>
              </Col>
            </Row>
            <Row>
              <Col
                className={[styles.textContainer, 'body-big'].join(' ')}
                sm={8}
                md={10}
                lg={7}
              >
                <p>{text}</p>
              </Col>
            </Row>
            <Row>
              <ActionButton
                actionButton={action_button}
                rounded
                styleKey="button_big_head"
              >
                {button_xs}
                {button}
              </ActionButton>
            </Row>
          </Container>
        </Div>
      </section>
    )
  }
}
