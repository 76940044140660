import React, { Component } from 'react'
import styles from './styles.module.scss'

export interface AnimatedBounceProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  visible?: boolean
  delayMS?: number
  delayInterval?: number
  onHidden?: () => void
  onVisible?: () => void
}

const DEFAULT_DELAY = 0
const DEFAULT_DELAY_INTERVAL = 50

export class AnimatedBounce extends Component<AnimatedBounceProps> {
  delay = () => {
    return this.props.delayMS != null ? this.props.delayMS : DEFAULT_DELAY
  }

  delayInterval = () => {
    return this.props.delayInterval != null
      ? this.props.delayInterval
      : DEFAULT_DELAY_INTERVAL
  }

  componentDidUpdate(lastProps) {
    const { visible, children, onVisible, onHidden } = this.props
    if (lastProps.visible !== visible) {
      var timeout = this.delay()
      if (Array.isArray(children)) {
        timeout += this.delayInterval() * children.length
      } else {
        timeout += this.delayInterval()
      }

      setTimeout(() => {
        visible && onVisible && onVisible()
        !visible && onHidden && onHidden()
      }, timeout)
    }
  }

  renderChild(child: any, index: number, classes: string[]) {
    const {
      visible,
      className,
      style,
      delayMS,
      delayInterval,
      children,
      onVisible,
      onHidden,
      ...rest
    } = this.props

    const mstyle = style || {}

    if (visible) {
      mstyle.transitionDelay = `${this.delay() + this.delayInterval() * index}ms`
    }

    return (
      <div key={index.toString()} className={classes.join(' ')} style={mstyle} {...rest}>
        {child}
      </div>
    )
  }

  render() {
    const { visible, className, children } = this.props

    const classes = [styles.base]
    if (visible) {
      classes.push(styles.visible)
    }
    if (className) {
      classes.push(className)
    }

    if (Array.isArray(children)) {
      return children.map((child, index) => this.renderChild(child, index, [...classes]))
    }

    return this.renderChild(children, 0, classes)
  }
}
