import React, { Component } from 'react'
import Lottie from 'lottie-react-web'
import { Div } from '~components'
import { Animations } from '~libs/assets'

export interface HamburgerProps extends React.HTMLAttributes<HTMLDivElement> {
  menuOpen: boolean
}

export interface HamburgerState {
  segments: number[]
  options: { loop: boolean; autoplay: boolean; animationData: any }
  menuState: 'burger' | 'menu' | 'animating'
}

const BurgerOptions = {
  loop: true,
  autoplay: true,
  animationData: Animations.hamburger,
}
const MenuOptions = {
  loop: false,
  autoplay: false,
  animationData: Animations.openclosemenu,
}

const openSegments = [0, 130]
const closeSegments = [131, 224]

class Hamburger extends Component<HamburgerProps, HamburgerState> {
  state: HamburgerState = {
    segments: null,
    options: BurgerOptions,
    menuState: 'burger',
  }

  timeout: NodeJS.Timer

  lottieRef = React.createRef<Lottie>()

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
  }

  UNSAFE_componentWillReceiveProps(nextProps: HamburgerProps) {
    if (nextProps.menuOpen !== this.props.menuOpen) {
      this.updateSegments(nextProps)
    }
  }

  shouldComponentUpdate(prevProps) {
    const { menuOpen } = this.props
    const { menuState } = this.state
    return menuState !== 'animating' || menuOpen !== prevProps.menuOpen
  }

  updateSegments = (props: HamburgerProps) => {
    const { menuOpen } = props
    const { menuState } = this.state

    const toMenuState = menuOpen ? 'menu' : 'burger'

    if (menuState !== toMenuState) {
      const newState: HamburgerState = {
        options: MenuOptions,
        segments: toMenuState === 'burger' ? closeSegments : openSegments,
        menuState: 'animating',
      }

      this.setState(newState)
      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(
        () =>
          this.setState({
            options: toMenuState === 'burger' ? BurgerOptions : MenuOptions,
            segments: toMenuState === 'burger' ? null : newState.segments,
            menuState: toMenuState,
          }),
        2000,
      )
    }
  }

  render() {
    const { onClick, className } = this.props
    const { segments, options } = this.state

    return (
      <Div onClick={onClick} className={className}>
        <Lottie
          ref={this.lottieRef}
          options={options}
          segments={segments}
          height="100%"
          width="100%"
        />
      </Div>
    )
  }
}

export default Hamburger
