import { observable, action } from 'mobx'
import { HemmaApi } from '~libs/api/HemmaApi'
import { IInputItem, IInputState } from './ApplicationUIStore/props'
import { tx } from '~libs/i18n'
import {
  EmailValidator,
  PhoneValidator,
  TextValidator,
  UUID4Validator,
} from '~libs/validators'
import { PhoneFormatter, UUID4Formatter } from '~libs/formatters'
import { disableScroll, enableScroll } from '~libs/utils'
import { Store } from './Store'

const defaultFields: { [key: string]: IInputItem } = {
  invitationCode: {
    value: '',
    label: tx('application_queue.invited_screen.code_label'),
    placeholder: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
    type: 'text',
    formatter: UUID4Formatter,
    maxLength: 36,
    validator: {
      func: UUID4Validator,
      options: {
        errorName: tx('misc.reference_code'),
      },
    },
  },

  email: {
    value: '',
    label: tx('misc.email'),
    type: 'email',
    validator: {
      func: EmailValidator,
      options: { errorName: tx('misc.email') },
    },
  },

  name: {
    value: '',
    label: tx('misc.name'),
    type: 'text',
    maxLength: 100,
    minLength: 2,
    validator: {
      func: TextValidator,
      options: { min: 2, max: 100, errorName: tx('misc.name') },
    },
  },

  inviteEmail: {
    value: '',
    label: tx('my_pages.invite_friend.friend_email'),
    type: 'email',
    validator: {
      func: EmailValidator,
      options: { errorName: tx('misc.email') },
    },
  },

  mobile: {
    label: tx('application.models.applicant.mobile'),
    type: 'tel',
    formatter: PhoneFormatter,
    maxLength: 18,
    validator: {
      func: PhoneValidator,
      options: { errorName: tx('application.models.applicant.mobile') },
    },
  },
}

const defaultInvitations: IInputItem[] = [
  {
    value: '',
    label: tx('application_queue.invite_screen.invite_label'),
    type: 'email',
    placeholder: tx('misc.email'),
    validator: {
      func: EmailValidator,
      options: { errorName: tx('misc.email') },
    },
  },
]

export class ApplicationQueueStore {
  rootStore: Store
  api: HemmaApi

  @observable
  fields = defaultFields

  @observable
  invitations = defaultInvitations

  @observable
  statuses: { [key: string]: 'idle' | 'pending' | 'error' } = {
    validateInvitationCode: 'idle',
    requestQueue: 'idle',
    sendInvitations: 'idle',
    requestInvitedQueue: 'idle',
  }

  @observable
  screenIndex = 0

  @observable
  visible: boolean = false

  lastQueueInvitationCode: ''
  lastQueueName: ''
  lastQueueEmail: ''
  bodyScrollTop = 0

  constructor(rootStore: Store, api: HemmaApi) {
    this.rootStore = rootStore
    this.api = api
  }

  @action reset = () => {
    this.fields = defaultFields
    this.invitations = defaultInvitations
  }

  @action open = () => {
    this.visible = true
    this.bodyScrollTop = disableScroll()
  }

  @action close = () => {
    this.visible = false

    enableScroll(this.bodyScrollTop)
    setTimeout(() => {
      this.screenIndex = 0
    }, 400)
  }

  @action closeAndReset = () => {
    this.close()
    setTimeout(() => {
      this.reset()
    }, 400)
  }

  @action openInvited = () => {
    this.goToInvitedScreen()
    this.open()
  }

  @action nextScreen = () => {
    this.screenIndex++
  }

  @action goToInvitedScreen = () => {
    this.screenIndex = 3
  }

  @action goToSorryScreen = () => {
    this.screenIndex = 5
  }

  @action goToApplication = () => {
    const invitationCode = this.fields.invitationCode.value
    const applicantState = this.rootStore.applicationUIStore.inputStateMap.applicant
      .applicant as IInputState

    const isInvitedField = applicantState.is_invited as IInputItem
    const invitationCodeField = applicantState.invitation_code as IInputItem

    isInvitedField.value = 'Ja'
    isInvitedField.triggers[0].active = true
    invitationCodeField.value = invitationCode
    invitationCodeField.ishidden = false
    invitationCodeField.label = tx('application.models.applicant.invitation_code_preset')

    this.rootStore.applicationUIStore.open()
    this.close()
  }

  @action
  requestInvitedQueue = async () => {
    const emailField = this.fields.email
    const mobileField = this.fields.mobile
    if (!this.validateItems([emailField, mobileField], true)) {
      return
    }

    this.statuses.requestInvitedQueue = 'pending'
    const response = await this.api.postQueueInvited({
      email: emailField.value,
      phone: mobileField.value,
      code: this.lastQueueInvitationCode,
    })
    if (response.ok) {
      this.statuses.requestInvitedQueue = 'idle'
      this.nextScreen()
    } else {
      this.statuses.requestInvitedQueue = 'error'
    }
  }

  @action
  validateInvitationCode = async () => {
    const field = this.fields.invitationCode
    if (!this.validateItem(field, true)) {
      return
    }
    this.lastQueueInvitationCode = field.value
    this.statuses.validateInvitationCode = 'pending'
    const response = await this.api.validateInvitationCode(field.value)
    if (response.ok) {
      const { valid, queue_open } = response.data
      if (valid) {
        if (queue_open) {
          this.goToApplication()
        } else {
          this.goToSorryScreen()
        }
      } else {
        field.error = tx('validators.invalid', {
          name: field.validator.options.errorName,
        })
      }
      this.statuses.validateInvitationCode = 'idle'
    } else {
      this.statuses.validateInvitationCode = 'error'
    }
  }

  @action
  requestQueue = async () => {
    const nameField = this.fields.name
    const emailField = this.fields.email
    if (!this.validateItems([nameField, emailField], true)) {
      return
    }
    this.lastQueueName = nameField.value
    this.lastQueueEmail = emailField.value
    this.statuses.requestQueue = 'pending'
    const response = await this.api.postQueue(emailField.value)
    if (response.ok) {
      this.statuses.requestQueue = 'idle'
      this.nextScreen()
    } else if (response.status === 409) {
      emailField.error = tx('application_queue.queue_screen.already_queued_error')
      this.statuses.requestQueue = 'idle'
    } else {
      this.statuses.requestQueue = 'error'
    }
  }

  @action
  sendInvitations = async () => {
    if (!this.validateItems(this.invitations, true)) {
      return
    }
    this.statuses.sendInvitations = 'pending'
    const response = await this.api.putInvite({
      name: this.lastQueueName,
      email: this.lastQueueEmail,
      invitations: this.invitations.map(item => item.value),
    })
    if (response.ok) {
      this.statuses.sendInvitations = 'idle'
      this.invitations = defaultInvitations
      this.screenIndex < 2 && this.nextScreen()
    } else {
      this.statuses.sendInvitations = 'error'
    }
  }

  @action
  addInvitationEmail = () => {
    const field = defaultInvitations[0]
    this.invitations.push(field)
  }

  @action
  removeInvitationEmail = (index: number) => {
    if (this.invitations.length <= 1) {
      return
    }
    this.invitations.splice(index, 1)
  }

  @action
  validateItem = (item: IInputItem, setError: boolean) => {
    if (item.validator) {
      const validationResult = item.validator.func(item.value, item.validator.options)
      if (setError) {
        item.error = validationResult.error
      }
      return validationResult.valid
    }
    return true
  }

  @action
  validateItems = (items: IInputItem[], setError: boolean) => {
    let valid = true
    items.some(item => {
      if (!this.validateItem(item, setError)) {
        valid = false
        if (!setError) {
          return true
        }
      }
      return false
    })
    return valid
  }
}
