import React, { Component } from 'react'
import styles from './styles.module.scss'

export interface ImageButtonProps extends React.HTMLProps<HTMLImageElement> {
  crossOrigin?: '' | 'anonymous' | 'use-credentials'
}

export class ImageButton extends Component<ImageButtonProps> {
  render() {
    const { className, ...rest } = this.props
    const classes = [styles.image]
    if (className) {
      classes.push(className)
    }
    return <img className={classes.join(' ')} {...rest} />
  }
}
