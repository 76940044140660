import React, { Component } from 'react'
import { Container, Row, Col, ImgBundle, Responsive } from '~components'
import { IThreeTextsSection } from '~models'
import styles from './styles.module.scss'

export interface ThreeTextsSectionProps {
  section: IThreeTextsSection
}

export class ThreeTextsSection extends Component<ThreeTextsSectionProps> {
  render() {
    const {
      text,
      title,
      title_xs,
      text_xs,
      strapline,
      texts,
      images,
    } = this.props.section

    return (
      <section
        className={[styles.section, 'd-flex flex-column justify-content-start'].join(' ')}
      >
        <Container main className={styles.container}>
          <Row className="d-flex justify-content-center">
            <Col lg={8} md={10}>
              <p className={'label strapline text-center'}>{strapline}</p>
              <h2 className="text-center">
                <Responsive>
                  {title_xs}
                  {title}
                </Responsive>
              </h2>
              <p className={['body-regular text-center', styles.mainBread].join(' ')}>
                <Responsive>
                  {text_xs}
                  {text}
                </Responsive>
              </p>
            </Col>
          </Row>
          <Row>
            {texts.map((item, index) => (
              <Col key={item.title} md={4}>
                <p
                  className={[
                    'label textleft d-none d-md-inline-block',
                    styles.numbering,
                  ].join(' ')}
                >
                  {index + 1}
                </p>
                <h3 className={'text-center text-md-left'}>{item.title}</h3>
                <p
                  className={[
                    'body-regular text-center text-md-left',
                    styles.smallText,
                  ].join(' ')}
                >
                  <Responsive>
                    {item.text_xs}
                    {item.text}
                  </Responsive>
                </p>
              </Col>
            ))}
          </Row>
        </Container>
        <ImgBundle images={images} className={styles.backgroundImage} />
      </section>
    )
  }
}
