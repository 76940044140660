import { HemmaApi } from '~libs/api/HemmaApi'
import { ApplicationUIStore } from './ApplicationUIStore'
import { AuthStore } from './AuthStore'
import { RfqStore } from './RfqStore'
import { MyPagesStore } from './MyPagesStore'
import { ApplicationQueueStore } from './ApplicationQueueStore'
import { UIStore } from './UIStore'

let store = null

export class Store {
  applicationUIStore: ApplicationUIStore
  applicationQueueStore: ApplicationQueueStore
  authStore: AuthStore
  rfqStore: RfqStore
  myPagesStore: MyPagesStore
  uiStore: UIStore

  constructor() {
    const hemmaApi = new HemmaApi()
    this.applicationUIStore = new ApplicationUIStore()
    this.applicationQueueStore = new ApplicationQueueStore(this, hemmaApi)
    this.authStore = new AuthStore(this, hemmaApi)
    this.rfqStore = new RfqStore(hemmaApi)
    this.myPagesStore = new MyPagesStore(this, hemmaApi)
    this.uiStore = new UIStore()
  }

  onMount = () => {
    this.uiStore.onMount()
  }
  onUnmount = () => {
    this.uiStore.onUnmount()
  }
}

export const initStore = () => {
  store = store != null ? store : new Store()
  return store
}
