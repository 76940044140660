import React, { Component } from 'react'
import { Icons } from '~libs/assets'
import styles from './styles.module.scss'
import { Container } from '../Bootstrap'
import { observer } from 'mobx-react'
import { findDOMNode } from 'react-dom'

export interface ListProps<T> {
  items: T[]
  renderHeader: string | (() => any)
  renderItem: (
    item?: T,
    index?: number,
    isFirstItem?: boolean,
    isLastItem?: boolean,
  ) => any
  renderEmptyView: string | (() => any)
  hasMoreItems?: boolean
  loadMoreItems?: () => void
  loading?: boolean
  badge?: number
}

@observer
export class List<T> extends Component<ListProps<T>> {
  containerRef = React.createRef<Container>()
  state = {
    itemHeight: 0,
    mountedZeroHeight: false,
  }

  timeout: NodeJS.Timer

  componentDidMount() {
    this.setItemHeight()
  }

  componentDidUpdate() {
    const { items } = this.props
    const { mountedZeroHeight } = this.state
    if (items && items.length && mountedZeroHeight) {
      this.setItemHeight()
    } else {
      this.timeout = setTimeout(() => this.setState({ mountedZeroHeight: true }), 1)
    }
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
  }

  setItemHeight = () => {
    const container =
      this.containerRef.current && (findDOMNode(this.containerRef.current) as HTMLElement)

    if (container == null) {
      return
    }
    const { items } = this.props
    const { itemHeight } = this.state
    const height = container.clientHeight

    if (itemHeight === 0 && height > 0 && items && items.length) {
      this.setState({ itemHeight: height / items.length })
    }
  }

  onLoadMore = () => {
    const { loadMoreItems, loading } = this.props
    if (loading) {
      return
    }
    loadMoreItems && loadMoreItems()
  }

  renderHeader = () => {
    const { renderHeader, badge } = this.props

    switch (typeof renderHeader) {
      case 'string':
        return (
          <h3 className={styles.header}>
            <span>{renderHeader}</span>{' '}
            {!!badge && (
              <div className={styles.badge}>
                <span>{badge}</span>
              </div>
            )}
          </h3>
        )

      case 'function':
        return renderHeader()
    }

    return null
  }

  renderEmptyView = () => {
    const { renderEmptyView } = this.props

    switch (typeof renderEmptyView) {
      case 'string':
        return <p className={styles.empty}>{renderEmptyView}</p>

      case 'function':
        return renderEmptyView()
    }

    return null
  }

  render() {
    const { items, renderItem, hasMoreItems } = this.props
    if (!items || !items.length) {
      return (
        <div className={styles.wrapper}>
          {this.renderHeader()}
          {this.renderEmptyView()}
        </div>
      )
    }
    const { itemHeight, mountedZeroHeight } = this.state

    const containerStyles = {
      maxHeight: 0,
    }

    if (mountedZeroHeight) {
      containerStyles.maxHeight = itemHeight ? itemHeight * (items.length + 1) : 500
    }

    return (
      <div className={styles.wrapper}>
        {this.renderHeader()}
        <Container
          ref={this.containerRef}
          fluid
          style={containerStyles}
          className={styles.container}
        >
          {items.map((item, index, arr) => {
            return renderItem(item, index, index === 0, index === arr.length - 1)
          })}
        </Container>
        {hasMoreItems && (
          <button className={styles.show_more} onClick={this.onLoadMore}>
            Visa fler <img src={Icons.arrow_down} />
          </button>
        )}
      </div>
    )
  }
}
