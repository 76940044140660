import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import Img, { GatsbyImageProps } from 'gatsby-image'
import { IImageBundle, IImage } from '~models'
import { UIStore } from '~stores/UIStore'
import styles from './styles.module.scss'

export interface ImgBundleProps extends GatsbyImageProps {
  images: IImageBundle
  uiStore?: UIStore
}

@inject('uiStore')
@observer
export class ImgBundle extends Component<ImgBundleProps> {
  image = () => {
    const { images, uiStore } = this.props
    const width = uiStore.windowWidth

    const test = (image, width) =>
      image.localFile && width < image.localFile.childImageSharp.large.presentationWidth

    if (!!width) {
      const key = Object.keys(images).find(key => test(images[key], width))
      if (key) {
        return images[key]
      }
    }
    return images.image_xl
  }

  render() {
    const { images, style, className, ...rest } = this.props
    const classes = [styles.image_wrapper]

    if (className) {
      classes.push(className as string)
    }

    const mclasses = [...classes]
    const image: IImage = images ? this.image() : null
    const fluidImage =
      image && image.localFile ? image.localFile.childImageSharp.large : null

    const mstyle = style || {}
    if (fluidImage) {
      mstyle['height'] = fluidImage.presentationHeight
    }

    const imgStyle = {
      objectFit: 'unset',
      width: 'auto',
    }

    return (
      <Img
        {...rest}
        className={mclasses.join(' ')}
        style={mstyle}
        imgStyle={imgStyle}
        fluid={fluidImage}
      />
    )
  }
}
