import React from 'react'
import { Provider } from 'mobx-react'
import DevTools from 'mobx-react-devtools'
import { initStore, Store } from '~stores/Store'

const DEV_TOOLS = process.env.GATSBY_MOBX_DEV_TOOLS === 'true'

interface Props {
  store: Store
}

export const withStore = Comp =>
  class MobxApp extends React.Component<Props> {
    store: Store

    static async getInitialProps({ query }) {
      return { slug: query.slug }
    }

    constructor(props: Props) {
      super(props)
      this.store = initStore()
    }

    componentDidMount() {
      this.store.onMount()
    }

    componentWillUnmount() {
      this.store.onUnmount()
    }

    render() {
      return (
        <>
          <Provider {...this.store}>
            <Comp {...this.props} />
          </Provider>
          {!!DEV_TOOLS && <DevTools />}
        </>
      )
    }
  }
