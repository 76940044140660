import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Container, Input, Button } from '~components'
import { AuthStore } from '~stores'
import { tx } from '~libs/i18n'
import { PidnFormatter } from '~libs/formatters'
import styles from './styles.module.scss'

export interface LoginSectionProps {
  authStore?: AuthStore
}

@inject('authStore')
@observer
export class LoginSection extends Component<LoginSectionProps> {
  onKeyPress = e => {
    const { authStore } = this.props
    const keycode = e.keyCode ? e.keyCode : e.which
    switch (keycode) {
      case 13: // Enter
        authStore.isLoginValid() && authStore.login()
        break
    }
  }

  render() {
    const { authStore } = this.props
    return (
      <section className={styles.section}>
        <Container className={styles.wrapper}>
          <h1>{tx('login.title')}</h1>

          <Input
            value={authStore.pidn}
            placeholder="ÅÅÅÅMMDD-NNNN"
            label={tx('misc.pidn')}
            type="tel"
            formatter={PidnFormatter}
            onChange={value => (authStore.pidn = value)}
            maxLength={13}
            onEnter={() => authStore.isLoginValid() && authStore.login()}
          />

          <Button
            disabled={!authStore.isLoginValid() || authStore.loginState === 'pending'}
            className={styles.login_button}
            onClick={authStore.login}
            onKeyPress={this.onKeyPress}
          >
            {tx('login.button')}
          </Button>
        </Container>
      </section>
    )
  }
}
