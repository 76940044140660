import { MoneyFormatter } from '~libs/formatters'
import { IInputState } from '../props'
import { NumberValidator } from '~libs/validators'
import { tx } from '~libs/i18n'

export const debt: IInputState = {
  type: {
    label: tx('application.models.debt.type'),
    type: 'select',
    selectableValues: [
      { name: 'Bolån', value: 'mortgage' },
      { name: 'Studielån', value: 'student' },
      { name: 'Leasingavbetalning', value: 'leasing' },
      { name: 'Kortkredit', value: 'credit' },
      { name: 'Blancoavbetalning', value: 'blanco' },
      { name: 'Billån', value: 'car' },
    ],
    triggers: [
      {
        value: 'mortgage',
        keys: [
          'has_multiple_borrowers',
          'number_borrowers',
          'collateral_type',
          'collateral_monthly_cost',
        ],
      },
    ],
  },
  amount: {
    label: tx('application.models.debt.amount'),
    overviewValue: '{value} kr',
    type: 'tel',
    formatter: MoneyFormatter,
    maxLength: 11,
    validator: {
      func: NumberValidator,
      options: {
        minValue: 0,
        maxValue: 100000000,
        errorName: tx('application.models.debt.amount'),
      },
    },
  },
  monthly_payment: {
    label: tx('application.models.debt.monthly_payment'),
    overviewValue: '{value} kr',
    type: 'tel',
    formatter: MoneyFormatter,
    maxLength: 7,
  },
  has_multiple_borrowers: {
    label: tx('application.models.debt.has_multiple_borrowers'),
    type: 'boolean',
    triggers: [
      {
        value: 'Ja',
        keys: ['number_borrowers'],
      },
    ],
  },
  number_borrowers: {
    ishidden: true,
    label: tx('application.models.debt.number_borrowers'),
    type: 'number',
    min: 1,
    max: 10,
  },
  collateral_type: {
    ishidden: true,
    label: tx('application.models.debt.collateral_type'),
    type: 'select',
    selectableValues: [
      { name: 'Villa', value: 'house' },
      { name: 'Bostadsrätt', value: 'condominium' },
    ],
    triggers: [
      {
        value: ['condominium', 'house'],
        keys: ['collateral_monthly_cost'],
      },
    ],
  },
  collateral_monthly_cost: {
    label: tx('application.models.debt.collateral_monthly_cost'),
    overviewValue: '{value} kr',
    type: 'tel',
    formatter: MoneyFormatter,
    maxLength: 9,
    validator: {
      func: NumberValidator,
      options: {
        minValue: 0,
        maxValue: 1000000,
        errorName: tx('application.models.debt.collateral_monthly_cost'),
      },
    },
  },
}
