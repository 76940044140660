import { action, observable } from 'mobx'
import { HemmaApi } from '~libs/api/HemmaApi'
import { ProductConfig, RfqView, Config } from '~libs/api/models'
import { delay } from '~libs/utils'

export class RfqStore {
  api: HemmaApi

  @observable
  sentRfqSignatureLink = false

  @observable
  state: {
    [key: string]: 'idle' | 'signingRfq' | 'verifyingRfq' | 'verifySuccess' | 'error'
  } = {}

  @observable
  lastError: { [key: string]: string } = {}

  @observable
  rfqView: { [key: string]: RfqView } = {}

  constructor(api: HemmaApi) {
    this.api = api
  }

  @action sendRfqSignatureLink = async (config: Config, email: string) => {
    setTimeout(() => (this.sentRfqSignatureLink = true), 2000)
  }

  @action createRfqSignature = async (
    key: string,
    config: Config,
    pidn: string,
    accept_end_user_agreement: boolean,
  ) => {
    const productConfig: ProductConfig = {
      product_id: TEST_PRODUCT_ID,
      config,
    }
    productConfig.config.applicant.accept_end_user_agreement = accept_end_user_agreement
    this.signRfq(key, pidn, productConfig)
  }

  @action signRfq = async (key: string, pidn: string, productConfig: ProductConfig) => {
    this.state[key] = 'signingRfq'

    const result = await this.api.postRfqSign({ pidn, product_config: productConfig })
    if (result.ok) {
      this.verifySignRfq(key)
    } else {
      this.lastError[key] = 'error: ' + result.status
      this.state[key] = 'error'
    }
  }

  @action verifySignRfq = async (key: string) => {
    this.state[key] = 'verifyingRfq'
    await delay(2000)
    while (this.state[key] === 'verifyingRfq') {
      const result = await this.api.getRfqSign()

      if (result.ok) {
        if (result.status === 200) {
          this.state[key] = 'verifySuccess'
          this.rfqView[key] = result.data as RfqView
          return
        } else {
          console.log('verifySignRfq', result.data)
          await delay(1000)
        }
      } else {
        this.lastError[key] = 'error: ' + result.status
        this.state[key] = 'error'
        return
      }
    }
  }

  @action cancelSignRfq = async (key: string) => {
    if (this.state[key] === 'verifyingRfq') {
      this.state[key] = 'idle'
    }
  }

  @action reset = async () => {
    this.sentRfqSignatureLink = false
    this.state = {}
  }
}

const TEST_PRODUCT_ID = '90fbebc9-3885-464f-bb9a-63cd7530c9bd'
const TEST_CONFIG = {
  loan: {
    amount: 4227983,
  },
  applicant: {
    pidn: '194705145037',
    name: 'Rickard Dahlberg',
    mobile: '+46 (0)8 943 217 92',
    email: 'henriknorberg@telia.com',
    civil_status: 'partner',
    number_children_under_18: 13,
    employment: {
      type: 'student',
    },
    income: {
      income_in_sek: true,
      monthly_gross_income: 24060,
    },
    other_debts: [
      {
        type: 'car',
        amount: 2032496,
      },
      {
        type: 'credit',
        amount: 18545,
      },
    ],
    pep: false,
    accept_end_user_agreement: true,
  },
  coapplicant: {
    pidn: '195306088070',
    name: 'Louise Persson',
    mobile: '+46 (0)53 29 62 65',
    email: 'olarsson@malmberg.org',
    civil_status: 'partner',
    number_children_under_18: 4,
    employment: {
      type: 'indefinite',
      employer: 'Andersson Haglund AB',
      role: 'Sub',
    },
    income: {
      income_in_sek: true,
      monthly_gross_income: 16111,
    },
    other_debts: [
      {
        type: 'student',
        amount: 8066,
      },
      {
        type: 'credit',
        amount: 21305,
      },
    ],
    pep: false,
    accept_end_user_agreement: true,
  },
  collateral: {
    address: {
      street: 'Villagatan 266',
      zip: '63572',
      city: 'Karlskoga',
    },
    estimated_market_price: 122600,
    type: 'condominium',
    apartment_id: '842',
    number_rooms: 36,
    housing_society: 'Jansson AB',
    property_id: 'fugiat 42',
    monthly_cost: 3395,
    living_area: 8713,
    mortgage: {
      amount: 141827,
      monthly_amortization: 932647,
    },
  },
}
