import { ZipFormatter } from '~libs/formatters'
import { NumberValidator } from '~libs/validators'
import { nbsp } from '~libs/constants'
import { tx } from '~libs/i18n'
import { IInputState } from '../props'

export const address: IInputState = {
  street: {
    label: tx('application.models.address.street'),
    type: 'text',
    minLength: 2,
    maxLength: 100,
  },
  zip: {
    label: tx('application.models.address.zip'),
    placeholder: '123 45',
    text: `Mitt postnummer\när${nbsp}{?}`,
    type: 'tel',
    formatter: ZipFormatter,
    validator: {
      func: NumberValidator,
      options: { minDigits: 5, errorName: 'postnummer' },
    },
    maxLength: 6,
  },
  city: {
    label: tx('application.models.address.city'),
    type: 'text',
    minLength: 2,
    maxLength: 100,
  },
}
