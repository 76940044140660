import React, { Component } from 'react'
import { Container, Row, Col, ImgBundle, Div, Responsive } from '~components'
import { IFloatLeftRightSection } from '~models'
import styles from './styles.module.scss'

export interface FloatLeftRightSectionProps {
  section: IFloatLeftRightSection
}

export class FloatLeftRightSection extends Component<FloatLeftRightSectionProps> {
  render() {
    const {
      strapline,
      title,
      title_xs,
      text,
      images,
      float_text_left,
    } = this.props.section
    return (
      <section className={`${styles.section}`}>
        {/* Image */}
        <Div className={styles.absolute}>
          <Container className={`${styles.height100md} ${styles.imageContainer}`}>
            <Row className={`h-100 ${styles.image}`}>
              {float_text_left && <Col xs={2} md={4} xl={3} />}
              <Col
                xs={float_text_left ? 10 : 12}
                md={float_text_left ? 8 : 4}
                lg={float_text_left ? 8 : 5}
                xl={float_text_left ? 7 : 6}
                className={`d-flex align-items-center ${
                  float_text_left ? 'justify-content-start' : 'justify-content-end'
                }`}
              >
                <ImgBundle images={images} />
              </Col>
            </Row>
          </Container>
        </Div>
        {/* Text */}
        <Container main>
          <Row>
            {!float_text_left && <Col md={4} lg={5} xl={6} />}
            <Col sm={12} md={float_text_left ? 9 : 8} lg={7} xl={6}>
              <p className={`label strapline text-left`}>{strapline}</p>
            </Col>
          </Row>
          <Row>
            {!float_text_left && <Col md={4} lg={5} xl={6} />}
            <Col md={float_text_left ? 9 : 8} lg={7} xl={6}>
              <h2 className={[styles.heading, 'text-left'].join(' ')}>
                <Responsive>
                  {title_xs}
                  {title}
                </Responsive>
              </h2>
            </Col>
          </Row>
          <Row>
            {!float_text_left && <Col md={4} lg={5} xl={6} />}
            <Col md={float_text_left ? 9 : 8} lg={7} xl={6}>
              <p className={[styles.text, 'body-regular text-left'].join(' ')}>{text}</p>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}
