import { observable, action } from 'mobx'
import { HemmaApi } from '~libs/api/HemmaApi'
import { User } from '~libs/api/models'
import { delay } from '~libs/utils'
import { PidnFormatter } from '~libs/formatters'
import { Store } from './Store'

export class AuthStore {
  rootStore: Store
  api: HemmaApi

  @observable currentUser: User
  @observable loggedIn: boolean = false
  @observable pidn: string = '' //'19850803-8299'

  @observable loginState: 'idle' | 'pending' | 'error'
  @observable loginError: string

  constructor(rootStore: Store, api: HemmaApi) {
    this.rootStore = rootStore
    this.api = api
  }

  @action
  login = async () => {
    this.loginState = 'pending'
    const result = await this.api.postLogin(PidnFormatter(this.pidn, true))
    if (result.ok) {
      this.verifyLogin()
    } else {
      this.loginState = 'error'
      this.loginError = 'error: ' + result.status
    }
  }

  @action verifyLogin = async () => {
    this.loginState = 'pending'
    await delay(2000)
    while (this.loginState === 'pending') {
      const result = await this.api.getLogin()

      if (result.ok) {
        if (result.status === 200) {
          this.loginState = 'idle'
          let currentUserResult = await this.getCurrentUser()
          if (currentUserResult.ok) {
            while (!this.currentUser) {
              currentUserResult = await this.getCurrentUser()
              if (!currentUserResult.ok || this.currentUser) {
                break
              }
              await delay(2000)
            }
            this.loggedIn = true
          } else {
            this.loginError = 'error: ' + currentUserResult.status
            this.loginState = 'error'
          }
        } else {
          await delay(1000)
        }
      } else {
        this.loginError = 'error: ' + result.status
        this.loginState = 'error'
      }
    }
  }

  @action
  logout = async () => {
    this.api.getLogout()
    this.currentUser = null
    this.loggedIn = false
    this.rootStore.myPagesStore.resetStore()
  }

  @action
  getCurrentUser = async () => {
    const response = await this.api.getUser()
    if (response.ok && response.data.quotes) {
      this.rootStore.myPagesStore.setQuotes(response.data.quotes)
      this.currentUser = response.data
    }
    return response
  }

  isLoginValid = () => {
    return !!this.pidn && this.pidn.length == 13
  }
}
