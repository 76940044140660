import {
  PidnFormatter,
  PhoneFormatter,
  NumberFormatter,
  UUID4Formatter,
} from '~libs/formatters'
import { tx } from '~libs/i18n'
import {
  EmailValidator,
  PhoneValidator,
  PidnValidator,
  BooleanValidator,
  TextValidator,
  UUID4Validator,
} from '~libs/validators'
import { employment } from './employment'
import { debt } from './debt'
import { income } from './income'
import { IInputState, IInputItem } from '../props'

export const applicant: IInputState = {
  name: {
    label: tx('application.models.applicant.name'),
    type: 'text',
  },
  pidn: {
    label: tx('application.models.applicant.pidn'),
    placeholder: 'ÅÅÅÅMMDD-NNNN',
    type: 'tel',
    formatter: PidnFormatter,
    maxLength: 13,
    validator: {
      func: PidnValidator,
      options: { errorName: tx('application.models.applicant.pidn') },
    },
  },
  mobile: {
    label: tx('application.models.applicant.mobile'),
    type: 'tel',
    formatter: PhoneFormatter,
    maxLength: 18,
    validator: {
      func: PhoneValidator,
      options: { errorName: tx('application.models.applicant.mobile') },
    },
  },
  email: {
    label: tx('application.models.applicant.email'),
    type: 'email',
    validator: {
      func: EmailValidator,
      options: { errorName: tx('application.models.applicant.email') },
    },
  },
  civil_status: {
    label: tx('application.models.applicant.civil_status'),
    type: 'select',
    selectableValues: [
      { name: 'Gift', value: 'married' },
      { name: 'Singel', value: 'single' },
      { name: 'Sambo', value: 'partner' },
    ],
  },
  has_children_under_18: {
    label: tx('application.models.applicant.has_children_under_18'),
    type: 'boolean',
    triggers: [
      {
        value: 'Ja',
        keys: ['number_children_under_18'],
      },
    ],
  },
  number_children_under_18: {
    ishidden: true,
    label: tx('application.models.applicant.number_children_under_18'),
    type: 'number',
    formatter: NumberFormatter,
    value: 1,
    min: 1,
    max: 20,
  },
  pep: {
    label: tx('application.models.applicant.pep'),
    label_secondary: tx('application.models.coapplicant.pep'),
    type: 'boolean',
    validator: {
      func: BooleanValidator,
      options: { value: 'Nej', errorName: tx('application.models.applicant.pep') },
    },
    triggers: [
      {
        value: 'Ja',
        keys: ['pep_true_message'],
      },
    ],
  },
  pep_true_message: {
    hidden: true,
    isMessage: true,
    text: tx('application.models.applicant.pep_error'),
  },
  employment,
  income,
  has_other_loans: {
    label: tx('application.models.applicant.has_other_loans'),
    label_secondary: tx('application.models.applicant.has_other_loans_secondary'),
    type: 'boolean',
    triggers: [
      {
        value: 'Ja',
        keys: ['other_loans'],
      },
    ],
  },
  other_loans: {
    ishidden: true,
    value: [],
    isArray: true,
    arrayTriggerKey: 'type',
    arrayTemplate: {
      type: {
        ...debt.type,
        selectableValues: (debt.type.selectableValues as any).filter(
          v => !['mortgage'].includes(v.value),
        ),
      },
      amount: debt.amount,
    },
  },
  has_other_properties: {
    label: tx('application.models.applicant.has_other_properties'),
    label_secondary: tx('application.models.applicant.has_other_properties_secondary'),
    type: 'boolean',
    triggers: [
      {
        value: 'Ja',
        keys: ['other_properties'],
      },
    ],
  },
  other_properties: {
    ishidden: true,
    value: [],
    isArray: true,
    arrayTriggerKey: 'collateral_type',
    arrayTemplate: {
      collateral_type: { ...debt.collateral_type, ishidden: false },
      amount: debt.amount,
      has_multiple_borrowers: debt.has_multiple_borrowers,
      number_borrowers: debt.number_borrowers,
      collateral_monthly_cost: debt.collateral_monthly_cost,
    },
  },
  is_invited: {
    label: tx('application.models.applicant.is_invited'),
    type: 'boolean',
    value: 'Nej',
    triggers: [
      {
        value: 'Ja',
        keys: ['invitation_code'],
      },
    ],
  },
  invitation_code: {
    ishidden: true,
    label: tx('application.models.applicant.invitation_code'),
    placeholder: tx('misc.reference_code'),
    type: 'text',
    formatter: UUID4Formatter,
    maxLength: 36,
    validator: {
      func: UUID4Validator,
      options: {
        errorName: tx('misc.reference_code'),
      },
    },
  },
}

export const has_coapplicant: IInputItem = {
  label: tx('application.models.coapplicant.has_coapplicant_number'),
  text: tx('application.models.coapplicant.has_coapplicant_number_text'),
  type: 'select',
  selectableValues: [{ name: 'Själv', value: '1' }, { name: 'Med en annan', value: '2' }],
  triggers: [{ type: 'copyValue', keys: ['has_coapplicant_number'] }],
}

export const has_coapplicant_number: IInputItem = {
  label: tx('application.models.coapplicant.has_coapplicant_number'),
  type: 'select',
  selectableValues: [{ name: '1', value: '1' }, { name: '2', value: '2' }],
  triggers: [{ type: 'copyValue', keys: ['has_coapplicant'] }],
}
