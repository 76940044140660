import React, { Component } from 'react'
import styles from './styles.module.scss'

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  styleKey?: string
  rounded?: boolean
  disabled?: boolean
  big?: boolean
  outlined?: boolean
}

export class Button extends Component<ButtonProps> {
  render() {
    const {
      styleKey,
      rounded,
      disabled,
      big,
      outlined,
      onClick,
      className,
      ...rest
    } = this.props
    const classes = [big ? styles.button_big : styles.button]
    if (className) {
      classes.push(className)
    }

    if (styleKey) {
      classes.push(styles[styleKey])
    }
    if (rounded) {
      classes.push(styles.rounded)
    }
    if (disabled) {
      classes.push(styles.disabled)
    }
    if (outlined) {
      classes.push(styles.outlined)
    }
    return (
      <button
        onClick={disabled ? undefined : onClick}
        className={classes.join(' ')}
        {...rest}
      />
    )
  }
}
