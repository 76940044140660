export const screenWidthSm = 540
export const screenWidthMd = 720
export const screenWidthLg = 992
export const screenWidthXl = 1200

export const headerHeightSm = 64
export const headerHeightMd = 88
export const headerHeightLg = 96

export const getCurrentHeaderHeight = () => {
  if (window.innerWidth < screenWidthMd) {
    return headerHeightSm
  }
  if (window.innerWidth < screenWidthLg) {
    return headerHeightMd
  }
  return headerHeightLg
}
