import React, { Component } from 'react'
import styles from './styles.module.scss'
import { Div, DivProps } from '../Div'

export interface ContainerProps extends DivProps {
  centered?: boolean
  main?: boolean
  fluid?: boolean
  application?: boolean
}

export class Container extends Component<ContainerProps> {
  render() {
    const { className, centered, main, fluid, application, ...rest } = this.props
    const classes = [fluid ? 'container-fluid' : 'container']

    if (className) {
      classes.push(className)
    }
    if (centered) {
      classes.push(styles.centered)
    }
    if (main) {
      classes.push(styles.main)
    }
    if (application) {
      classes.push(styles.application)
    }
    return <Div className={classes.join(' ')} {...rest} />
  }
}
