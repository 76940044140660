import { MoneyFormatter, NumberFormatter } from '~libs/formatters'
import { nbsp } from '~libs/constants'
import { tx } from '~libs/i18n'
import { address } from './address'
import { mortgage } from './mortgage'
import { IInputState } from '../props'

export const collateral: IInputState = {
  address,
  estimated_market_price: {
    label: tx('application.models.collateral.estimated_market_price'),
    placeholder: '5 000 000',
    text: tx('application.models.collateral.estimated_market_price_text', { nbsp }),
    overviewValue: '{value} kr',
    type: 'tel',
    formatter: MoneyFormatter,
    maxLength: 11,
  },
  type: {
    label: 'Typ av boende',
    type: 'select',
    selectableValues: [
      { name: 'Villa', value: 'house' },
      { name: 'Bostadsrätt', value: 'condominium' },
    ],
    triggers: [
      {
        value: 'condominium',
        keys: ['monthly_cost', 'number_rooms', 'apartment_number', 'housing_society'],
      },
      {
        value: 'house',
        keys: ['property_id', 'living_area'],
      },
    ],
  },
  apartment_number: {
    ishidden: true,
    label: tx('application.models.collateral.apartment_number'),
    type: 'tel',
  },
  number_rooms: {
    ishidden: true,
    label: tx('application.models.collateral.number_rooms'),
    type: 'number',
    min: 1,
    max: 100,
  },
  living_area: {
    ishidden: true,
    label: tx('application.models.collateral.living_area'),
    overviewValue: '{value} kvm',
    type: 'number',
    min: 0,
    max: 10000,
    formatter: NumberFormatter,
  },
  property_id: {
    ishidden: true,
    label: tx('application.models.collateral.property_id'),
    type: 'text',
  },
  housing_society: {
    ishidden: true,
    label: tx('application.models.collateral.housing_society'),
    type: 'text',
  },
  monthly_cost: {
    ishidden: true,
    label: tx('application.models.collateral.monthly_cost'),
    type: 'tel',
    overviewValue: '{value} kr',
    formatter: MoneyFormatter,
    maxLength: 7,
  },
  mortgage,
}
