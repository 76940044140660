import { create, ApisauceInstance } from 'apisauce'
import { User, B4RfqSignRequest, RfqView, StartBankidResponse, Invoice } from './models'
import { Invite } from './models/Invite'

export class HemmaApi {
  client: ApisauceInstance
  constructor() {
    this.client = create({
      baseURL: process.env.GATSBY_HEMMA_API_URL,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      },
      timeout: 10000,
      withCredentials: true,
    })
  }

  postLogin = async (personalNumber: string) =>
    this.client.post<StartBankidResponse>('/user/login', { personalNumber })

  getLogin = async () => this.client.get('/user/login')

  getLogout = async () => this.client.get('/user/logout')

  getUser = async () => this.client.get<User>('/user')

  postRfqSign = async (body: B4RfqSignRequest) =>
    this.client.post<StartBankidResponse>('/rfq/sign', body)

  getRfqSign = async () => this.client.get<RfqView | { status: 'pending' }>('/rfq/sign')

  postQuoteSign = async (id: string) =>
    this.client.post<StartBankidResponse>(`/quote/sign/${id}`)

  getQuoteSign = async (id: string) =>
    this.client.get<{} | { status: 'pending' }>(`/quote/sign/${id}`)

  putQuoteAutogiro = async (id: string, account: string) =>
    this.client.put<null>(`/quote/autogiro/${id}`, { account })

  putQuoteContact = async (id: string, emails: string[], numbers: string[]) =>
    this.client.put<null>(`/quote/contact/${id}`, {
      email_addresses: emails,
      phone_numbers: numbers,
    })

  downloadDocument = async (id: string) =>
    this.client.get<Blob>(`/document/download/${id}`, null, {
      responseType: 'blob',
    })

  getDocuments = async (query: string) =>
    this.client.get<TPaginatedResponse<Document>>(`/document?${query || ''}`)

  getInvoices = async (query: string) =>
    this.client.get<TPaginatedResponse<Invoice>>(`/invoice?${query || ''}`)

  postQueue = async (email: string) =>
    this.client.post<{ status: 'ok' | 'error' }>('/queue', { email })

  postQueueInvited = async (body: { code: string; email: string; phone: string }) =>
    this.client.post<null>('/queue/invited', body)

  putInvite = async (body: Invite) =>
    this.client.put<{ status: 'ok' | 'error' }>('/invite', body)

  validateInvitationCode = async (code: String) =>
    this.client.get<{ valid: boolean; queue_open: boolean }>(`/invite/valid/${code}`)
}

export type TPaginatedList = 'getDocuments' | 'getInvoices'

export type TPaginatedResponse<T> = { data: T[]; meta: { total: number } }
