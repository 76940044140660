import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export interface SeoProps {
  title?: string
  description?: string
  keywords?: string
}

export class Seo extends Component<SeoProps> {
  render() {
    const { title, description, keywords } = this.props
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={'Swace Digital'} />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="UTF-8" />
      </Helmet>
    )
  }
}
